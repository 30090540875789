import * as Cookies from "js-cookie";

export const useSession = () => {
    return {
        setSession: (sess = {}) => {
            Cookies.remove("session");
            Cookies.set("session", sess, { expires: 365 });
        },
        getSession: () => {
            const sessionCookie = Cookies.get("session");

            if (sessionCookie === undefined) {
                return {};
            } else {
                return JSON.parse(sessionCookie);
            }
        },
        isLogin: () => {
            const sessionCookie = Cookies.get("session");

            if (sessionCookie !== undefined &&
                    sessionCookie !== null &&
                    sessionCookie !== "null") {
                let aaa = JSON.parse(sessionCookie);
                if ("uk" in aaa)
                    return true;
            }
            return false;
        },
        getMode: () => {
            const sessionCookie = Cookies.get("session");

            if (sessionCookie === undefined) {
                return 2;
            } else {
                let a = JSON.parse(sessionCookie);
                return a["mode"]
            }
        },
        getName: () => {
            const sessionCookie = Cookies.get("session");

            if (sessionCookie === undefined) {
                return "";
            } else {
                let a = JSON.parse(sessionCookie);
                if (a !== null)
                    return a["name"];
                return "";
            }
        }
    };
};
