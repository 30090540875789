import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

/*
주문시 아래 내용 100% 필독후 동의하시는 분들에 한해 주문가능하며, 필수 안내사항에 대해 고객 미숙지로 인한 과실발생 및 패널티에 대해서 당사에 책임이 없음과 주문시 필수 안내사항 필독 및 동의하셨다라는 간주하에 진행됨을 참고 부탁드립니다.

0. 복지지원금 적용기준은 접수기준이 아닌 "개통기준"으로 적용됩니다.
(선개통후 발송이 필수이며, 번호이동가입의 경우는 수령후 개통가능합니다(단, 정책변동 없을시만))

1. 주문접수시 기기배정후 순차적 해피콜 진행하고 있으니 시간여유두고 기다려 주시면 빠른 연락드리도록하겠습니다.

2. 단말기 불량시 개통일 포함 14일 이내 동일제품으로 교품가능합니다.(교품시 AS센터 불량확인서 필수 첨부)
단, 기기 재고 상황에 따라 교품이 아닌 취소가 될수도 있는점 참고 부탁드립니다.

3. 고객 단순변심 사유로는 개통취소 불가하오니 신중한 구매 부탁드립니다.
더불어, 고객 부주의로 인한 제품 스크래치 및 파손의 경우에는 교환이 불가합니다.

4. 제휴카드 등록시 개통일 포함 14일 이내 등록가능하며, 그 이후 수정 불가능합니다.
* 단말기 할인형(라이트할부카드) 카드 등록시 해당 카드사로 신규/교체 발급 후 대표번호(1599-5168)로 연락필수

5. 자동이체 할인형 카드는 등록기간 제한없습니다.
* 카드사로 신규/교체 발급후 대표번호(1599-5168) or 고객센터(114)로 자동이체 변경필수

6. 단말기 보험가입은 필수가입이 아니므로, 필요시 개통일 포함 60일 이내 가입 및 상품변경 가능하며, 그 이후 가입 및 타보험 상품으로 변경불가합니다.
(아이폰케어 상품은 일반 보험상품과는 가입조건이 상이하므로, 개통시 별도 문의필요)

7. T패밀리몰 사이트는 임직원몰 사이트입니다. 개통전 아래의 필수서류 선첨부후 개통진행이 가능합니다.
첨부는 T패밀리몰 카톡채널이나 T패밀리몰 업무용 연락처(010-4079-3466)로 문자 전송해주시면 됩니다.

- 임직원 본인 - 자격득실확인서("정부24"에서 "발급용"으로 발급된 사진본 or 원본) 첨부필요
- 임직원 가족 - 자격득실확인서("정부24"에서 "발급용"으로 발급된 사진본 or 원본), 가족관계증명서 첨부필요
* 자격득실확인서 발급시 주의사항 : 국민건강보험공단 발급건은 불인정, 정부24에서 미리보기용이나 열람용 발급건은 불인정됩니다.

8. 약정기간 이내 해지시 위약금 발생 및 요금제 필수유지기간 미이행시 패널티가 발생하오니 유의부탁드립니다.

9. 개통시 적용된 할인제도에 따라 요금제 필수유지기간 유지후 변경가능
- 공시지원금(단말기 할인형 약정) 개통시 : D+183일 유지후 LTE/5G 구분없이 월정액 42,000원 이상 요금제로 변경가능
(단, 42,000원 이하 요금제로 변경시 공시차액금 별도부과)
- 선택약정(요금 할인형 약정) 개통시 : M+3개월 유지후 변경가능(기본료에 대한 제약없음)

* 5G로 개통후 LTE 요금제로 변경시 처리방법 : LTE 단말기로 유심변경 -> LTE 요금제로 변경 -> 5G 단말기로 다시 유심변경후 사용가능
* 요금제 변경시 데이터 초과금 및 공시 차액금 발생 주의
*/
const LegalModal = ({
    showLegalModal,
    setShowLegalModal,
    setShowOrderModal,
}) => {

    const [agree, setAgree] = useState(false)

    const handleSelect = e => {

        if (!agree)
            return alert("약관 동의 해주세요")

        setShowOrderModal(true)
        setShowLegalModal(false)
    };

    return (
        <Modal show={showLegalModal} onHide={e => setShowLegalModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                단말기 주문후 필수 확인 사항 입니다.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <table className="table noneborder pt-2" style={{fontSize:'14px'}}>
              <tbody>

                <tr>
                  <td>주문시 아래 내용 100% 필독후 동의하시는 분들에 한해 주문가능하며, 필수 안내사항에 대해 고객 미숙지로 인한 과실발생 및 패널티에 대해서 당사에 책임이 없음과 주문시 필수 안내사항 필독 및 동의하셨다라는 간주하에 진행됨을 참고 부탁드립니다.</td>
                </tr>
                <tr>
                  <td><b>0.</b>	복지지원금 적용기준은 접수기준이 아닌 "개통기준"으로 적용됩니다.<br/>(선개통후 발송이 필수이며, 번호이동가입의 경우는 수령후 개통가능합니다(단, 정책변동 없을시만))</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>1.</b>	주문접수시 기기배정후 순차적 해피콜 진행하고 있으니 시간여유두고 기다려 주시면 빠른 연락드리도록하겠습니다.</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>2.</b>	 단말기 불량시 개통일 포함 14일 이내 동일제품으로 교품가능합니다.(교품시 AS센터 불량확인서 필수 첨부)<br/>단, 기기 재고 상황에 따라 교품이 아닌 취소가 될수도 있는점 참고 부탁드립니다.</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>3.</b>	고객 단순변심 사유로는 개통취소 불가하오니 신중한 구매 부탁드립니다.<br/>더불어, 고객 부주의로 인한 제품 스크래치 및 파손의 경우에는 교환이 불가합니다.</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>4.</b>	제휴카드 등록시 개통일 포함 14일 이내 등록가능하며, 그 이후 수정 불가능합니다.<br/>
                  * 단말기 할인형(라이트할부카드) 카드 등록시 해당 카드사로 신규/교체 발급 후 대표번호(1599-5168)로 연락필수</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>5.</b>	자동이체 할인형 카드는 등록기간 제한없습니다.<br/>* 카드사로 신규/교체 발급후 대표번호(1599-5168) or 고객센터(114)로 자동이체 변경필수</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>6.</b>	단말기 보험가입은 필수가입이 아니므로, 필요시 개통일 포함 60일 이내 가입 및 상품변경 가능하며, 그 이후 가입 및 타보험 상품으로 변경불가합니다.<br/>
                  (아이폰케어 상품은 일반 보험상품과는 가입조건이 상이하므로, 개통시 별도 문의필요)</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>7.</b>	T패밀리몰 사이트는 임직원몰 사이트입니다. 개통전 아래의 필수서류 선첨부후 개통진행이 가능합니다.
                  첨부는 T패밀리몰 카톡채널이나 T패밀리몰 업무용 연락처(010-4079-3466)로 문자 전송해주시면 됩니다.
                  <br/>
                  <br/>- 임직원 본인 - 자격득실확인서("정부24"에서 "발급용"으로 발급된 사진본 or 원본) 첨부필요
                  <br/>- 임직원 가족 - 자격득실확인서("정부24"에서 "발급용"으로 발급된 사진본 or 원본), 가족관계증명서 첨부필요
                  <br/>* 자격득실확인서 발급시 주의사항 : 국민건강보험공단 발급건은 불인정, 정부24에서 미리보기용이나 열람용 발급건은 불인정됩니다.
                  </td>
                </tr>
                <tr>
                  <td className="pt-2"><b>8.</b>	개통시 적용된 할인제도에 따라 요금제 필수유지기간 유지후 변경가능<br/>
                  - 공시지원금(단말기 할인형 약정) 개통시 : D+183일 유지후 LTE/5G 구분없이 월정액 42,000원 이상 요금제로 변경가능<br/>
                  (단, 42,000원 이하 요금제로 변경시 공시차액금 별도부과)<br/>
                  - 선택약정(요금 할인형 약정) 개통시 : M+3개월 유지후 변경가능(기본료에 대한 제약없음)<br/><br/>
                  * 5G로 개통후 LTE 요금제로 변경시 처리방법 : LTE 단말기로 유심변경 - LTE 요금제로 변경 - 5G 단말기로 다시 유심변경후 사용가능<br/>
                  * 요금제 변경시 데이터 초과금 및 공시 차액금 발생 주의
                  </td>
                </tr>
                <tr>
                  <td className="pt-2"><b>9.</b>	약정기간 이내 해지시 위약금 발생 및 요금제 필수유지기간 미이행시 패널티가 발생하오니 유의부탁드립니다.</td>
                </tr>
                <tr>
                  <td className="pt-2"><b>10.</b>	개통자 = 실사용자 사용이 원칙이며, 유심기변은 개통후 6개월 이후부터 가능합니다.<br/>
                  6개월 이전 유심변경시 불편법 사용으로 인해 본사 패널티 환수대상으로 위약금이 발생될 수 있는점 참고부탁드립니다.
                  </td>
                </tr>
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer style={{justifyContent: 'center', paddingTop: '20px', paddingBottom: '20px'}}>
                <div style={{width: '100%', 'textAlign' : 'center'}}>
                    <div style={{display: 'inline-block'}}>
                        <Form.Check type={'checkbox'} onClick={e => setAgree(!agree)} id={`default-1`} label={`위의 내용을 확인하였습니다.`} />
                    </div>
                    <br /><br />
                    <div style={{display: 'inline-block'}}>
                        <Button variant="primary" onClick={handleSelect}>&nbsp;&nbsp;  확인  &nbsp;&nbsp;</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>);
};

export default LegalModal;


/*

★ 단말기 주문시 필수 확인 사항입니다.

주문시 아래 내용 100% 필독후 동의하시는 분들에 한해 주문가능하며, 필수 안내사항에 대해 고객 미숙지로 인한 과실발생 및 패널티에 대해서 당사에 책임이 없음과 주문시 필수 안내사항 필독 및 동의하셨다라는 간주하에 진행됨을 참고 부탁드립니다.

0. 복지지원금 적용기준은 접수기준이 아닌 "개통기준"으로 적용됩니다.
(선개통후 발송이 필수이며, 번호이동가입의 경우는 수령후 개통가능합니다(단, 정책변동 없을시만))

1. 주문접수시 기기배정후 순차적 해피콜 진행하고 있으니 시간여유두고 기다려 주시면 빠른 연락드리도록하겠습니다.

2. 단말기 불량시 개통일 포함 14일 이내 동일제품으로 교품가능합니다.(교품시 AS센터 불량확인서 필수 첨부)
단, 기기 재고 상황에 따라 교품이 아닌 취소가 될수도 있는점 참고 부탁드립니다.

3. 고객 단순변심 사유로는 개통취소 불가하오니 신중한 구매 부탁드립니다.
더불어, 고객 부주의로 인한 제품 스크래치 및 파손의 경우에는 교환이 불가합니다.

4. 제휴카드 등록시 개통일 포함 14일 이내 등록가능하며, 그 이후 수정 불가능합니다.
* 단말기 할인형(라이트할부카드) 카드 등록시 해당 카드사로 신규/교체 발급 후 대표번호(1599-5168)로 연락필수

5. 자동이체 할인형 카드는 등록기간 제한없습니다.
* 카드사로 신규/교체 발급후 대표번호(1599-5168) or 고객센터(114)로 자동이체 변경필수

6. 단말기 보험가입은 필수가입이 아니므로, 필요시 개통일 포함 60일 이내 가입 및 상품변경 가능하며, 그 이후 가입 및 타보험 상품으로 변경불가합니다.
(아이폰케어 상품은 일반 보험상품과는 가입조건이 상이하므로, 개통시 별도 문의필요)

7. T패밀리몰 사이트는 임직원몰 사이트입니다. 개통전 아래의 필수서류 선첨부후 개통진행이 가능합니다.
첨부는 T패밀리몰 카톡채널이나 T패밀리몰 업무용 연락처(010-4079-3466)로 문자 전송해주시면 됩니다.

- 임직원 본인 - 자격득실확인서("정부24"에서 "발급용"으로 발급된 사진본 or 원본) 첨부필요
- 임직원 가족 - 자격득실확인서("정부24"에서 "발급용"으로 발급된 사진본 or 원본), 가족관계증명서 첨부필요
* 자격득실확인서 발급시 주의사항 : 국민건강보험공단 발급건은 불인정, 정부24에서 미리보기용이나 열람용 발급건은 불인정됩니다.

8. 개통시 적용된 할인제도에 따라 요금제 필수유지기간 유지후 변경가능
- 공시지원금(단말기 할인형 약정) 개통시 : D+183일 유지후 LTE/5G 구분없이 월정액 42,000원 이상 요금제로 변경가능
(단, 42,000원 이하 요금제로 변경시 공시차액금 별도부과)
- 선택약정(요금 할인형 약정) 개통시 : M+3개월 유지후 변경가능(기본료에 대한 제약없음)
* 요금제 변경시 데이터 초과금 및 공시 차액금 발생 주의

9. 약정기간 이내 해지시 위약금 발생 및 요금제 필수유지기간 미이행시 패널티가 발생하오니 유의부탁드립니다.

10. 개통자 = 실사용자 사용이 원칙이며, 유심기변은 개통후 6개월 이후부터 가능합니다.
6개월 이전 유심변경시 불편법 사용으로 인해 본사 패널티 환수대상으로 위약금이 발생될 수 있는점 참고부탁드립니다.

*/