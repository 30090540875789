import { useEffect, useState } from 'react';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { useSession } from '../hooks/useSession.js';

import PopupModal from '../components/modals/PopupModal.js';
import PopupFormModal from '../components/modals/PopupFormModal.js';
import Footer from '../components/navigations/Footer.js';

const Login  = () => {

	const { setSession, getSession, isLogin } = useSession();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isFormVisible, setFormVisible] = useState(false);

	useEffect(() => {
		if (isLogin())
			window.location = "/";

		if (window.Kakao.Auth.getAccessToken()) {

		}
	}, []);

	const handleKakao = async () => {
		try {
			new Promise((resolve, reject) => {
				if (!window.Kakao) {
					reject('인스턴스 없음');
				}
				window.Kakao.Auth.login({
					success: res => {
						console.log("res", res)

						window.Kakao.API.request({
						    url: '/v2/user/me',
						    success: function(kresponse) {

								let url = `https://api.tfamily.co.kr/api/main/login`;
								let params = { "social" : "KAKAO", "skey" : kresponse.id, };

								axios.get(url, { params: params})
									  .then(response => {
											if (response.data.error_code === 200) {
												setSession({...response.data.data, ...{"isKakao" : true} });
												window.location = "/";
											} else if(response.data.error_code === 410) {
												/*
												if( response.data.status === 'B') {

												} else {
													window.location = `/register/?token=${kresponse.id}`;
												}*/
												window.location = `/register/?token=${kresponse.id}`;
											} else {
												window.alert(response.data.error_msg);
											}
									  });
						    },
						    fail: function(error) {
						        console.log(error);
						    }
						});
					},
					fail: err => {
						console.error(err);
						console.error(err);
						console.error(err);console.error(err);
					},
				});
			});
		} catch (err) {
			console.error(err);
		}
	};

	const handleSubmit = async () => {
		let url = `https://api.tfamily.co.kr/api/main/login`;
		let params = { "email" : email, "passwd" : password, };
		axios.get(url, { params: params})
			 .then(response => {
				if (response.data.error_code === 200) {
					setSession({...response.data.data, ...{"isKakao" : false} });
					window.location = "/";
				} else {
					alert(response.data.error_msg);
				}
			 });
	};

	return (
		<>
			<Container>

				<br /><br />

				<Row>
				 	<Col></Col>
					<Col className="p-3 loginbox" xs={4}>
						<img className="img-fluid p-2" src="/img/logo_login.png" />
						<div><span className="redpoint"></span></div>
						<h4><b>로그인</b></h4>

						<div className="pb-4" style={{fontSize:'14px'}}>T패밀리몰은 사전 승인된 고객사 및 협회 등의&nbsp;<span className="txtGood">임직원 및 가족을 위한 공간</span>입니다. <br/>
							<div className="pt-2"><span className="txtGood">최고의 혜택</span> 과&nbsp;<span className="txtGood">서비스</span>를 누려보세요.</div>
						</div>


						<FloatingLabel controlId="floatingInput" label="이메일 주소" className="mb-3">
							<Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} value={email} />
						</FloatingLabel>

						<FloatingLabel controlId="floatingPassword" label="비밀번호" className="mb-4">
							<Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} />
						</FloatingLabel>

						<div className="d-grid gap-2">
							<Button variant="primary p-2" onClick={handleSubmit}><b>로그인</b></Button>
						</div>

						<div className="d-grid gap-2 mt-3">
							<Button variant="primary p-2" className="btn-kakao" onClick={handleKakao}>
								<img src="/img/kakao_logo.png" className="logo-kakao"/>&nbsp;&nbsp;<b>카카오로 시작하기</b>
							</Button>
						</div>
						<br />

						<Row className="font-14">
							<Nav className="d-flex justify-content-between " style={{paddingLeft:'12px'}}>
								<Nav.Item>
									<Nav.Link className="link" href="/register/">회원가입</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className="link" href="/lost/">비밀번호 찾기</Nav.Link>
								</Nav.Item>
							</Nav>
						</Row>
					</Col>
					<Col></Col>
				</Row>
				<br />
			</Container>

			<Footer />

			<PopupModal
					setFormVisible={setFormVisible}
				/>

			{isFormVisible &&
				<PopupFormModal
						isFormVisible={isFormVisible}
						setFormVisible={setFormVisible}
					/>}

		</>
	);
};

export default Login;
