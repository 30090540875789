import { useEffect, useState } from 'react';
import axios, { post } from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { useSession } from '../hooks/useSession.js';
import Footer from '../components/navigations/Footer.js';

const Register = params => {

	const { isLogin } = useSession();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [username, setUsername] = useState('');

	const [phonenumber, setPhonenumber] = useState('');
	const [authkey, setAuthkey] = useState('');
	const [authnumber, setAuthnumber] = useState('');
	const [isAuthComplete, setAuthComplete] = useState(false);
	const [isAuthnumberRequest, setAuthnumberRequest] = useState(false);

	const [img, setImg] = useState(null);

	const [isAgree, setAgree] = useState(false);

	const [kakao, setKakao] = useState('');

	useEffect(() => {

		if (isLogin())
			window.location = "/";

		let query = new URLSearchParams(params.location.search);
		if (query.get('token'))
			setKakao(query.get('token'))

	}, []);

	const handleGetAuthNumber = async () => {

		if (phonenumber === "")
			return;

		let url = `https://api.tfamily.co.kr/api/mobile/sendCertiSms`;
        let headers = { };
        let params = { "phone_number" : phonenumber };
		setAuthnumberRequest(true)
		axios.get(url, { headers : headers, params: params})
            			  .then(response => {
							  	console.log(response.data)
						  		if (response.data.error_code === 200) {
									setAuthkey(response.data.data)
									setAuthnumberRequest(true)
								} else {
								 	alert(response.data.error_msg);
								}
						  });
	};

	const handleCheckAuthNumber = async () => {

		if (authkey === "" || authnumber === "")
			return;

		let url = `https://api.tfamily.co.kr/api/mobile/confirmCertiSms`;
		let headers = { };
		let params = { "k" : authkey, "v" : authnumber };
		axios.get(url, { headers : headers, params: params})
						  .then(response => {
								console.log(response)
								if (response.data.error_code === 200) {
									setAuthComplete(response.data.data)
									alert("인증되었습니다");
								} else {
									setAuthComplete(false);
									alert(response.data.error_msg);
								}
						  });
	};

	const handleSignup = async () => {

		if (kakao === "") {
			if (email === "" || password === "" || phonenumber === "" || username == "") {
				alert("입력하신 정보가 부족합니다!");
				return;
			}
			if (password !== password2) {
				alert("비밀번호가 일치하지 않습니다")
				return;
			}
		} else {
			if (email === "" || phonenumber === "" || username == "")
				return;
		}

		if (!isAuthComplete) {
			alert("휴대폰 인증을 진행해주세요")
			return;
		}

		if (!isAgree) {
			alert("이용약관 동의 해주세요")
			return;
		}

		let url = `https://api.tfamily.co.kr/api/main/signup`;
		// let url = `http://crm.philgookang.com/post.php`;
		let headers = { 'content-type': 'multipart/form-data' };

		const formData = new FormData();
   		formData.append('file', img);
		formData.append('email', email);
		formData.append('phone_number', phonenumber);
		formData.append('username', username);
		if (kakao !== "") {
			formData.append('social', "KAKAO");
			formData.append('skey', kakao);
		} else {
			formData.append('passwd', password);
		}

		post(url, formData, { headers : headers })
		  .then(response => {
				console.log("signup", response)
				if (response.data.error_code === 200) {
					//alert("회원가입 되었습니다. 다시 로그인 해주세요!");
					window.location = "/login/";
				} else {
					alert(response.data.error_msg)
				}
		  });
	};

	return (
		<>
			<Container>

				<br /><br /><br />
				<Row>
					<Col></Col>
					<Col xs={12} style={{textAlign: 'center'}}>
					<img style={{maxWidth:'100%'}} src="/img/logo_login.png"/>
					</Col>
					<Col></Col>
				</Row>
                <Row>
                    <Col></Col>
					<Col md={4} xs={12}>
						<h4><b>회원가입</b></h4>
						<h6 className="mb-4">* 필수 입력 사항입니다</h6>

						<FloatingLabel controlId="floatingInput" label="이름" className="mb-3">
                            <Form.Control type="text" placeholder="이름" onChange={e => setUsername(e.target.value)} value={username} />
                        </FloatingLabel>

						<FloatingLabel controlId="floatingInput" label="이메일 주소" className="mb-3">
							<Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} value={email} />
						</FloatingLabel>

						{kakao === "" &&
							<>
								<FloatingLabel controlId="floatingPassword" label="비밀번호" className="mb-3">
									<Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} />
								</FloatingLabel>

		                        <FloatingLabel controlId="floatingPassword" label="비밀번호 확인" className="mb-3">
									<Form.Control type="password" placeholder="Password" onChange={e => setPassword2(e.target.value)} value={password2} />
								</FloatingLabel>
							</>}

						<br />

						<Row>
							<Col xs={8}>
								<FloatingLabel controlId="floatingInput" label="휴대폰 번호" className="mb-3">
									<Form.Control type="email" placeholder="01012345678" onChange={e => setPhonenumber(e.target.value)} value={phonenumber} disabled={isAuthnumberRequest  || isAuthComplete} />
								</FloatingLabel>
		                    </Col>
							<Col xs={4} style={{textAlign:'right'}}>
								<Button style={{"height" : "58px"}} variant="primary" onClick={handleGetAuthNumber} disabled={isAuthnumberRequest || isAuthComplete}>인증번호 받기</Button>
		                    </Col>
		                </Row>

						{isAuthnumberRequest &&
							<Row>
								<Col xs={9}>
									<FloatingLabel controlId="floatingInput" label="인증번호" className="mb-3">
										<Form.Control type="email" placeholder="0000" onChange={e => setAuthnumber(e.target.value)} value={authnumber} disabled={isAuthComplete} />
									</FloatingLabel>
			                    </Col>
								<Col xs={3}>
									<Button style={{"height" : "58px"}} variant="primary" onClick={handleCheckAuthNumber} disabled={isAuthComplete}>인증하기</Button>
			                    </Col>
			                </Row>}

						<br />

						<Form.Label htmlFor="inputPassword5">사원증 또는 명함 사진 첨부</Form.Label>
 						<Form.Control type="file" onChange={e => setImg(e.target.files[0])} />

						<br />

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="약관 동의" checked={isAgree} onChange={e => setAgree(!isAgree)} />
                        </Form.Group>

						<div>
							<a className="agreement" href="https://tfamily.co.kr/agree/our.html" target="_blank"> 웹사이트 약관동의 (필수)</a>
						</div>

						<div className="pt-1"><div><div><label>
							<a className="agreement" href="https://tfamily.co.kr/agree/private.html" target="_blank">개인정보 수집 및 이용동의 (필수)</a>
						</label></div></div></div>

						<br />

                        <div className="d-grid gap-2">
                            <Button variant="primary py-2" onClick={handleSignup}><b>회원가입</b></Button>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>

				<br />

				<Row>
					<Col></Col>
					<Col  md={4} xs={12}>
						<Nav className="font-14 justify-content-between">
							<Nav.Item>
								<Nav.Link className="link" href="/login/">로그인</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link className="link" href="/lost/">비밀번호 찾기</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
					<Col></Col>
				</Row>
				<br />
				<br />
			</Container>

			<Footer />
		</>
	);
};

export default Register;
