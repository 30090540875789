import { useEffect, useState } from 'react';
import {useLocation} from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import BoardViewContent from '../components/boards/BoardViewContent.js';
import Footer from '../components/navigations/Footer.js';
import NavigationSearchbar from '../components/navigations/NavigationSearchbar';
import NavigationLinks from '../components/navigations/NavigationLinks';

import { useSession } from '../hooks/useSession.js';

const BoardView  = (props) => {

    const { getSession } = useSession();

    const { type, id } = useParams();
    const [board, setBoard] = useState({})

    useEffect(() => {
        let fetchData = async () => {
            let session = getSession();
            let url = `https://api.tfamily.co.kr/api/board/get`;
    		let params = { "uk" : session.uk, ...{ "idx" : id } };
    		axios.get(url, { params: params})
    			 .then(response => {
    				if (response.data.error_code === 200) {
                        console.log(response.data);
                        setBoard(response.data.data);
    				} else {
    					alert(response.data.error_msg);
    				}
    			 });
        };
        fetchData();
    }, []);

    return (
        <>
            <NavigationSearchbar />

            {'title' in board &&
                <BoardViewContent
                        type={type}
                        board={board}
                    />}
            <Footer />
        </>);
}

export default BoardView;
