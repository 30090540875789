import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

const CombinedListModal = ({
    combinedList,

    combinedDiscount,
    setCombinedDiscount,

    isCombinedListModalVisible,
    setCombinedListModalVisible,
}) => {
    return (
        <Modal show={isCombinedListModalVisible} onHide={e => setCombinedListModalVisible(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    유무선 결합 설정
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {combinedList.map((c, i) => {
                        if (combinedDiscount === i)
                            return (<ListGroup.Item action onClick={e => {setCombinedDiscount(i); setCombinedListModalVisible(false)}} active>{c}</ListGroup.Item>)
                        return (<ListGroup.Item action onClick={e => {setCombinedDiscount(i); setCombinedListModalVisible(false)}}>{c}</ListGroup.Item>)
                    })}
                </ListGroup>
            </Modal.Body>
        </Modal>)
};

export default CombinedListModal;
