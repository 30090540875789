import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { useSession } from '../../hooks/useSession.js';
import { isMobile } from 'react-device-detect';
import "./NavigationSearchbar.scss";
import { NavDropdown } from 'react-bootstrap';

const NavigationSearchbar = () => {

    const { getSession } = useSession();

    return (
        <>
            <Navbar variant="light" className="NavigationSearchbar">
                <Container>
                    <Navbar.Brand href="/">
                        <img alt="" src="/img/logo_tfamily.png" className="d-inline-block align-top" width="160" />{' '}
                    </Navbar.Brand>

                    {isMobile ?  <Nav style={{paddingRight:'12px'}}>
                        <NavDropdown title="메뉴" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/board/notice">공지사항</NavDropdown.Item>
                            <NavDropdown.Item href="/board/review">이벤트</NavDropdown.Item>
                            <NavDropdown.Item href="/reset/">비밀번호 변경</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/logout/">로그아웃</NavDropdown.Item>
                        </NavDropdown>
                    </Nav> : <>
                    <Navbar.Collapse className="font-14 justify-content-end">
                        <Nav.Link href="/board/notice">공지사항</Nav.Link>
                        <Nav.Link href="/board/review">이벤트</Nav.Link>
                        {!getSession().isKakao &&
                            <Nav.Link href="/reset/">비밀번호 변경</Nav.Link>}
                        <Nav.Link href="/logout/">로그아웃</Nav.Link>
                       
                    </Navbar.Collapse>
                    </> }
                  
                </Container>
            </Navbar>
        </>
    )
};

export default NavigationSearchbar;

/**
 * 
                  

 */