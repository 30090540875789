import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { useSession } from '../hooks/useSession.js';

import NavigationSearchbar from '../components/navigations/NavigationSearchbar';
import NavigationLinks from '../components/navigations/NavigationLinks';

import ProductListThumbnails from '../components/products/ProductListThumbnails';
import Footer from '../components/navigations/Footer.js';

const Home = () => {

    const { isLogin } = useSession();

    useEffect(() => {
        if (!isLogin())
            window.location = '/login/';
    }, []);

    return (
        <>
            <NavigationSearchbar />


            <br /><br />

            <ProductListThumbnails />

            <Footer />
        </>
    );
};

export default Home;
