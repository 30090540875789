import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const CalendarModal = ({
    showCal,
    setAfterDay,
    setAfterDate,
    setShowCal
}) => {
    const [today,setTdoay] = useState(null);
    const [dayArray, setDayArray] = useState([31, 91, 95, 125, 183 ]);
    useEffect(()=>{
        setTdoay(getToday());
    },[]);
    

    function getToday(){
        var date = new Date();
        var year = date.getFullYear();
        var month = ("0" + (1 + date.getMonth())).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
    
        return year + "-" + month + "-" + day;
    }
    
    return (<Modal show={showCal} onHide={e => setShowCal(false)}>
        <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    요금제 변경가능 날짜
                </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <table  style={{fontSize:'14px'}} className="table  table-striped">
                    <tbody>
                        <tr>
                            <td className="py-3" onClick={()=>{
                                setAfterDay(0);
                                setShowCal(false);
                            }}>미적용</td>
                        </tr>
                        <tr>
                            <td className="py-3">
                             <i className="fa-solid fa-calendar-check"></i> &nbsp;{today}
                            </td>
                        </tr>
                        <tr>
                            <td className="py-3" onClick={()=>{
                                setAfterDay(-1);
                                setShowCal(false);
                            }}>서비스 유지</td>
                        </tr>
                        {dayArray.map((day,index)=>{
                            return <>
                                <tr key={'today'+index}>
                                    <td className="py-3" onClick={()=>{
                                         //(new Date()).getDate() + day
                                        let today = new Date();
                                        let setDate = new Date();

                                        setDate.setDate( today.getDate() + day);
                                        
                                        setAfterDay(day);
                                        setAfterDate( setDate);
                                        setShowCal(false);
                                    }}>{day}일 {95 === day ? "(당일포함)" : null }</td>
                                </tr>
                            </>;
                        })}
                    </tbody>
                </table>
            </Modal.Body>
    </Modal>);
}

export default CalendarModal;