import * as Cookies from "js-cookie";

export const usePopup = () => {
    return {
        setPopupCookie: (sess = {}) => {
            Cookies.remove("popup_cookie");
            Cookies.set("popup_cookie", sess, { expires: 1 });
        },
        getPopupCookie: () => {
            const sessionCookie = Cookies.get("popup_cookie");

            if (sessionCookie === undefined) {
                return {};
            } else {
                return JSON.parse(sessionCookie);
            }
        },
        isPopupHide: () => {
            const sessionCookie = Cookies.get("popup_cookie");

            if (sessionCookie !== undefined &&
                    sessionCookie !== null &&
                    sessionCookie !== "null") {
                let aaa = JSON.parse(sessionCookie);
                if ("hide_popup_in_login_1" in aaa)
                    return true;
            }
            return false;
        },
    };
};
