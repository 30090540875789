import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import './ProductListHeader.scss';

const ProductListHeader = ({
    devices,
    filter,
    setFilter,
}) => {

    const check = (field, direction) => {
        if (filter &&
            "sort_field" in filter &&
            filter["sort_field"] === field &&
            filter["sort_direction"] === direction)
            return true;
        return;
    }

    return (
        <>
            <div className="ProductListHeader">
                <Navbar variant="light">
                    <Container>
                        <Nav className="product-count-title">
                            <Nav.Link eventKey="disabled" disabled>
                                <b>상품 <span>{devices.length}</span>개</b>
                            </Nav.Link>
                        </Nav>
                        <Nav className="justify-content-end">
                            <Nav.Link href="#" className={check("ordering", "DESC") ? "text-bold" : ""} onClick={e => {
                                setFilter({"sort_field" : "ordering", "sort_direction" : "DESC"})
                                return false;
                            }}>
                                인기순
                            </Nav.Link>
                            <Nav.Link eventKey="disabled" disabled>|</Nav.Link>
                            <Nav.Link href="#" className={check("released_date", "ASC") ? "text-bold" : ""} onClick={e => {
                                setFilter({"sort_field" : "released_date", "sort_direction" : "ASC"})
                                return false;
                            }}>
                                출시순
                            </Nav.Link>
                            <Nav.Link eventKey="disabled" disabled>|</Nav.Link>
                            <Nav.Link href="#" className={check("release_price", "DESC") ? "text-bold" : ""} onClick={e => {
                                setFilter({"sort_field" : "release_price", "sort_direction" : "DESC"})
                                return false;
                            }}>
                                가격순
                            </Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        </>
    );
};

export default ProductListHeader;
