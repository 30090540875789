import { useEffect, useState } from 'react';
import {useLocation} from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import BoardList from '../components/boards/BoardList.js';
import Footer from '../components/navigations/Footer.js';
import NavigationSearchbar from '../components/navigations/NavigationSearchbar';
import NavigationLinks from '../components/navigations/NavigationLinks';

import { useSession } from '../hooks/useSession.js';

const Board  = (props) => {

    const { getSession } = useSession();

    const { type } = useParams();
    const [filter, setFilter] = useState({ page : 1, size : 20, type : type.toLocaleUpperCase() })
    const [boards, setBoards] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        let fetchData = async () => {
            let session = getSession();
            let url = `https://api.tfamily.co.kr/api/board/lists`;
    		let params = { "uk" : session.uk, ...filter };
    		axios.get(url, { params: params})
    			 .then(response => {
    				if (response.data.error_code === 200) {
                        setTotal(response.data.data.total_cnt)
                        setBoards(response.data.data.elements)
    				} else {
    					alert(response.data.error_msg);
    				}
    			 });
        };
        fetchData();
    }, [filter]);

    return (
        <>
            <NavigationSearchbar />
            <BoardList
                    filter={filter}
                    setFilter={setFilter}
                    type={type}
                    total={total}
                    boards={boards}
                />
            <Footer />
        </>);
}

export default Board;
