import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';

const OrderModal = ({
    showOrderModal,
    setShowOrderModal,

    device,
    info,
    colors,

    memories,
    memory,
    activeColor,
    signupType,
    signupTarget,
    installment,
    fee,
    submitFull
}) => {

    const [relation, setRelation] = useState(0);
    const [name, setName] = useState('');
    const [startNumber, setStartNumber] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [personalNumber, setPersonalNumber] = useState('');
    const [address, setAddress] = useState('');

    const [card_month, setCard_month] = useState('');
    const [card_number, setCard_number] = useState('');

    const [pay_company, setPay_company] = useState('');
    const [pay_number, setPay_number] = useState('');

    const [cardForm, setCardForm] = useState(false);
    const [bankForm, setBankForm] = useState(false);

    const handleSelect = e => {
        if ( name === "" || startNumber === "" || contactNumber === "" || personalNumber === "" || address === "" ) {
            alert("정보를 올바르게 기입해주세요.");
            return false;
        }

        if( cardForm && (card_month === "" || card_number === "" ||  pay_company === "" ) ) {
            return false;
        }

        if( bankForm && (pay_number === "" || pay_company === "") ) {
            return false;
        }

        submitFull(name, startNumber, contactNumber, personalNumber, address, pay_company, pay_number, card_month, card_number);
        return false;
    };

    return (
        <Modal show={showOrderModal} onHide={e => setShowOrderModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    신청서
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{fontSize:'14px'}}>
                    <Row>
                        <Col></Col>
    					<Col className="p-3" xs={12}>
                            <Form onSubmit={(e)=>{   e.preventDefault(); e.stopPropagation(); handleSelect(); return false;}}>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">개통자 성함</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="개통자 성함" value={name} onChange={e => setName(e.target.value)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">개통 전화번호</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="개통 전화번호" value={startNumber} onChange={e => setStartNumber(e.target.value)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">해피콜받을 연락처</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="해피콜받을 연락처" value={contactNumber} onChange={e => setContactNumber(e.target.value)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">개통자 주민번호</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="개통자 주민번호" value={personalNumber} onChange={e => setPersonalNumber(e.target.value)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">택배수령 주소</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="택배수령 주소" value={address} onChange={e => setAddress(e.target.value)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">요금납부정보</Form.Label>
                                    <Col sm="9">
                                    <Form.Select aria-label="Default select example" onChange={e=>{
                                       setCardForm(false);
                                       setBankForm(false);
                                       if( e.target.value === "신용카드" ) {
                                        setCardForm(true);
                                       } else if ( e.target.value == "계좌이체") {
                                        setBankForm(true);
                                       }
                                    }}>
                                        {signupType === 0 ? <>
                                            <option>미선택</option>
                                            <option>계좌이체</option>
                                            <option>신용카드</option>
                                        </> : null}
                                        {signupType === 1 ? <>
                                            <option>기존유지</option>
                                        </> : null}
                                        {signupType === 2 ? <>
                                            <option>미선택</option>
                                            <option>계좌이체</option>
                                            <option>신용카드</option>
                                        </> : null}
                                      
                                    </Form.Select>
                                    </Col>
                                </Form.Group>
                                {cardForm ? <>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                        <Form.Label column sm="3">카드사/유효기간</Form.Label>
                                        <Col sm="4">
                                            <Form.Select onChange={(e)=>{
                                                setPay_company( e.target.value );
                                            }}>
                                            <option value="">미선택</option>
                                            <option value="신한카드">신한카드</option>
                                            <option value="현대카드">현대카드</option>
                                            <option value="롯데카드">롯데카드</option>
                                            <option value="KB국민카드">KB국민카드</option>
                                            <option value="우리카드">우리카드</option>
                                            <option value="하나카드">하나카드</option>
                                            <option value="삼성카드">삼성카드</option>
                                            </Form.Select>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Control type="text" placeholder="유효기간" value={card_month} onChange={e => {
                                                setCard_month(e.target.value);
                                            }}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                        <Form.Label column sm="3">카드번호</Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder="카드번호" value={card_number} onChange={e => {
                                                setCard_number(e.target.value);
                                            }}/>
                                        </Col>
                                    </Form.Group>
                                </> : null}
                                {bankForm ? <>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                        <Form.Label column sm="3">은행명</Form.Label>
                                        <Col sm="9">
                                            <Form.Select onChange={(e)=>{
                                                setPay_company( e.target.value );
                                            }}>
                                            <option value="">미선택</option>
                                            <option value="국민은행">국민은행</option>
                                            <option value="신한은행">신한은행</option>
                                            <option value="하나은행">하나은행</option>
                                            <option value="산업은행">산업은행</option>
                                            <option value="케이뱅크">케이뱅크</option>
                                            <option value="카카오뱅크">카카오뱅크</option>
                                            <option value="기업은행">기업은행</option>
                                            <option value="농협은행">농협은행</option>
                                            <option value="씨티은행">씨티은행</option>
                                            <option value="SC제일은행">SC제일은행</option>
                                            <option value="대구은행">대구은행</option>
                                            <option value="부산은행">부산은행</option>
                                            <option value="경남은행">경남은행</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                        <Form.Label column sm="3">계좌번호</Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder="계좌번호" value={pay_number} onChange={e => {
                                                //setPay_company(e.target.value);
                                                setPay_number(e.target.value);
                                            }}/>
                                        </Col>
                                    </Form.Group>
                                </> : null}
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm="12" style={{textAlign: 'center'}}>
                                        <Button variant="primary" type="submit">신청</Button>
                                        {' '}
                                        <Button variant="secondary" onClick={e => setShowOrderModal(false)}>취소</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>);
};

export default OrderModal;

/*
<select class="form-control"><option value="">미선택</option><option value="국민은행">국민은행</option><option value="신한은행">신한은행</option><option value="하나은행">하나은행</option><option value="산업은행">산업은행</option><option value="케이뱅크">케이뱅크</option><option value="카카오뱅크">카카오뱅크</option><option value="기업은행">기업은행</option><option value="농협은행">농협은행</option><option value="씨티은행">씨티은행</option><option value="SC제일은행">SC제일은행</option><option value="대구은행">대구은행</option><option value="부산은행">부산은행</option><option value="경남은행">경남은행</option></select>
<select class="form-control"><option value="">미선택</option><option value="신한카드">신한카드</option><option value="현대카드">현대카드</option><option value="롯데카드">롯데카드</option><option value="KB국민카드">KB국민카드</option><option value="우리카드">우리카드</option><option value="하나카드">하나카드</option><option value="삼성카드">삼성카드</option></select>
 <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">개통자와의 관계</Form.Label>
                                    <Col sm="9">
                                        <ButtonGroup aria-label="Basic example">
                                            <Button variant={relation === 0 ? "danger" : "outline-danger"} onClick={e => setRelation(0)}>본인</Button>
                                            <Button variant={relation === 1 ? "danger" : "outline-danger"} onClick={e => setRelation(1)}>부모님</Button>
                                            <Button variant={relation === 2 ? "danger" : "outline-danger"} onClick={e => setRelation(2)}>자녀</Button>
                                            <Button variant={relation === 3 ? "danger" : "outline-danger"} onClick={e => setRelation(3)}>친척</Button>
                                            <Button variant={relation === 4 ? "danger" : "outline-danger"} onClick={e => setRelation(4)}>배우자</Button>
                                            <Button variant={relation === 5 ? "danger" : "outline-danger"} onClick={e => setRelation(5)}>지인</Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                                
*/