import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

/* bootstrap css */
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";

/* pages */
import Home from './pages/Home.js';
import Detail from './pages/Detail.js';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import Reset from './pages/Reset.js';
import Lost from './pages/Lost.js';
import Logout from './pages/Logout.js';
import Board from './pages/Board.js';
import BoardView from './pages/BoardView.js';
import BoardManage from './pages/BoardManage.js';

ReactDOM.render((
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/detail/:idx" component={Detail} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset" component={Reset} />
            <Route exact path="/lost" component={Lost} />
            <Route exact path="/board/:type" component={Board} />
            <Route exact path="/board/:type/:id" component={BoardView} />
            <Route exact path="/board/manage/:type/:id" component={BoardManage} />
        </Switch>
    </BrowserRouter>),
    document.getElementById('root')
);
