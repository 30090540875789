import { useEffect, useState } from 'react';

import './ProductDetailImage.scss';

const ProductDetailImage = ({
    device,
    info,
    colors,

    activeColor,
    setActiveColor,
}) => {
    return (
        <>
            <div className="ProductDetailImage">
                <div className="big-image">
                    {colors.length > 0 &&
                        <img src={`https://api.tfamily.co.kr/uploads/device/${device.idx}_${colors[activeColor].hex}.jpg`} />}
                </div>
            </div>
        </>
    );
};

export default ProductDetailImage;
