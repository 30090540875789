import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

const CombinedDiscountModal = ({
    combinedList,
    isCombinedDiscountModalVisible,
    setCombinedDiscountModalVisible,
    combinedDiscount,
    setCombinedDiscount,

    isCombinedListModalVisible,
    setCombinedListModalVisible,
}) => {
    return (
        <Modal show={isCombinedDiscountModalVisible} onHide={e => setCombinedDiscountModalVisible(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    유무선 결합 설정
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table bordered size="sm">
                    <tbody>
                        <tr>
                            <td style={{backgroundColor: "#efefef", verticalAlign: "middle", textAlign: "center", width: "140px"}}><b>결합상품 선택</b></td>
                            <td style={{backgroundColor: "#fff"}}>
                                <Button style={{"width" : "100%"}} variant="light" onClick={e => setCombinedListModalVisible(true)}>
                                    {combinedList[combinedDiscount]}
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>)
};

export default CombinedDiscountModal;
