import { useEffect, useState } from 'react';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import ProductListHeader from './ProductListHeader';

import './ProductListThumbnails.scss';

import { useSession } from '../../hooks/useSession.js';

const ProductListThumbnails = () => {

    const { getSession } = useSession();

    const [devices, setDevices] = useState([]);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        fetchData();
    }, [filter]);

    const fetchData = async () => {
        let session = getSession();
        let url = `https://api.tfamily.co.kr/api/mobile/models`;
		let headers = { };
		let params = { "uk" : session.uk, ...filter };
		axios.get(url, { headers : headers, params: params})
    		  .then(response => {
    				console.log("devices", response)
    				if (response.data.error_code === 200) {
                        setDevices(response.data.data);
    				} else {
                        alert(response.data.error_msg);
    				}
    		  });
    };

    const commas = x => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            <ProductListHeader
                    devices={devices}
                    filter={filter}
                    setFilter={setFilter}
                />

            <div className="ProductListThumbnails">
                <Container>
                    <Row md={4} xs={2} className="g-4">
                        {devices.map((group, i) => {
                            let device = group.devices[0];
                            let info = JSON.parse(device.info);
                            let displayField = info.find(item => item.field === "크기");
                            let typeField = info.find(item => item.field === "디스플레이");
                            let color = JSON.parse(device.color);
                            console.log(device);
                            return (
                                <Col key={i}>
                                    <Card onClick={e => window.location = `/detail/${group.PRODUCT_GRP_ID}?sign_type=${device.sign_type}`}>
                                        <Card.Img variant="top" src={`https://api.tfamily.co.kr/uploads/device/${device.idx}_${color[0].hex}.jpg`} />
                                        <Card.Body>
                                            <div className="BadgeGroup">
                                                {device.is_5g === 1 &&
                                                    <><Badge bg="sk">5G휴대폰</Badge>&nbsp;</>}
                                                {device.sign_type === "M" ? <><Badge bg="secondary"> <i className="fa-solid fa-bolt"></i> 번호이동</Badge>&nbsp;</> : null}
                                                {device.sign_type === "N" ? <><Badge bg="secondary2"> <i className="fa-solid fa-newspaper"></i> 신규가입</Badge>&nbsp;</> : null}
                                                {device.sign_type === "C" ? <><Badge bg="secondary3"> <i className="fa-solid fa-clapperboard"></i> 기기변경</Badge>&nbsp;</> : null}
                                                {device.discount_type === "S" ? <><Badge bg="secondary4"> <i className="fa-solid fa-calendar"></i> 선택약정</Badge>&nbsp;</> : null}
                                                {device.discount_type === "G" ? <><Badge bg="secondary5"> <i className="fa-solid fa-coins"></i> 공시지원금</Badge>&nbsp;</> : null}
                                            </div>
                                            <Card.Title>{group.name}</Card.Title>
                                            <div className="card-price">
                                               <span className="strike">{commas(device.release_price)}</span>원
                                            </div>
                                            <div className="card-monthly-payment">
                                                <strong>{commas(device.release_price - device.subsidy - device.support)}</strong>원
                                                <br />
                                                요금제 <span className="plan">{device.fee_name}</span>
                                            </div>

                                 
                                            <div className="card-total-price">
                                                {commas(device.subsidy + device.support)}원 할인
                                            </div>

                                            <div className="card-specs hide">
                                                <table>
                                                    <tbody>
                                                {displayField && 'field' in displayField &&
                                                    <tr>
                                                        <td style={{width:'60px'}}><b>디스플레이</b></td>
                                                        <td>{displayField.value.trim()}</td>
                                                    </tr>}
                                                {typeField && 'field' in typeField &&
                                                <>
                                                    <tr><td colSpan={2}><br/></td></tr>
                                                    <tr>
                                                        <td style={{width:'60px'}}><b>화면종류</b></td>
                                                        <td>
                                                            <div dangerouslySetInnerHTML={{ __html: typeField.value.trim().replace("\n", "<br />") }} />
                                                        </td>
                                                    </tr>
                                                </>}
                                                </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProductListThumbnails;
