import { useEffect, useState } from 'react';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { usePopup } from '../../hooks/usePopup.js';

import "./PopupModal.scss";

const PopupModal = ({
    setFormVisible,
}) => {

    const { setPopupCookie, getPopupCookie, isPopupHide } = usePopup();

    const [isVisible, setVisible] = useState(false);
    const [popups, setPopups] = useState([]);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        let url = `https://api.tfamily.co.kr/api/mobile/init`;
		let params = { };
		axios.get(url, { params: params})
			 .then(response => {
				if (response.data.error_code === 200) {
                    if (!isPopupHide() && response.data.data.popup.length > 0) {
                        setPopups(response.data.data.popup);
                        setVisible(true);
                    }
				} else {
					alert(response.data.error_msg);
				}
			 });
    }, []);

    const handleHidepPopoupForOneDay = () => {
        setPopupCookie({ "hide_popup_in_login_1" : true })
        setVisible(false);
    };

    const handleSelect = e => {

    };

    const handlePreSignup = e => {
        setVisible(false);
        setFormVisible(true);
    };

    return (
        <Modal className="PopupModal" show={isVisible} onHide={e=>setVisible(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Slider {...settings}>
                    {popups.map((popup, i) => {
                        return (
                            <div className="popup">
                                <img className="popup-img" src={`https://api.tfamily.co.kr/${popup.img}`} alt={popup.title} />
                                {popup.type === 'S' &&
                                    <>
                                        <br />
                                        <center>
                                            <Button variant="light" onClick={handlePreSignup}>사전예약참가</Button>
                                        </center>
                                    </>}
                            </div>)
                    })}
                </Slider>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={handleHidepPopoupForOneDay}>
                    오늘 하루동안 팝업창 닫기
                </Button>
            </Modal.Footer>
        </Modal>);
};

export default PopupModal;
