import { Container, Table, Button } from "react-bootstrap";

import BoardPagination from './BoardPagination.js';

const BoardList = ({
    filter,
    setFilter,
    type,
    total,
    boards,
}) => {
    const nameChange = (val) => {
        const arr = [...val];  // Convert string to array
        arr[1] = "*";          // Set char c at pos i
        return arr.join(''); // Back to string
    }
    return (<>
        <Container>
            <div className="board_title">
                <h3 className="board_notice_title">

                    {type === 'notice' &&
                        <>공지사항</>}

                    {type === 'review' &&
                        <>이벤트게시판</>}

                </h3>
            </div>
            <div className="pb-5">
                <div className="min-board-height">
                    <Table className="board_notice_table" hover>
                        <thead>
                            <tr>
                                
                                <th className="text-center" width="80">번호</th>
                                <th>제목</th>
                                <th width="140">작성자</th>
                                <th width="140">작성일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {boards.length === 0 &&
                                <>
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="empty_notice">

                                                {type === 'notice' &&
                                                    <>작성된 공지사항이 없습니다.</>}

                                                {type === 'review' &&
                                                    <>진행중인 이벤트가 없습니다.</>}

                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            {boards.map((b, i) => {
                                return (
                                    <tr>
                                        <td className="text-center">{boards.length-i}</td>
                                        <td onClick={e => { window.location = `/board/${type}/${b.idx}` }}>
                                            {b.title}
                                        </td>
                                        <td>{b.customer && b.customer.length === 0 ? "관리자" : b.customer ? nameChange(b.customer[0].username) : "" }</td>
                                        <td style={{width:'140px', fontSize: '12px'}}>{b.created_at.substring(0,10)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <div style={{textAlign : "right"}}>
                        {type === 'review' ? <Button variant="primary" onClick={e => { window.location = `/board/manage/${type}/${0}` }}>등록하기</Button> : null }
                    </div>

                    <div style={{textAlign : "center"}}>
                        <BoardPagination
                                _currentPage={filter.page}
                                _limitPerPage={filter.size}
                                _totalCount={total}
                                onclickPage={(pageNum, url) => {
                                    var filter_ = {...filter};
                                    filter_.page = pageNum
                                    setFilter(filter_)
                                }}
                            />
                    </div>
                </div>
            </div>
        </Container>
    </>);
}

export default BoardList;


/*
 <!--
                                        <td>
                                            <div onClick={e => { window.location = `/board/manage/${type}/${b.idx}` }}>
                                                수정
                                            </div>
                                        </td>
*/