import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './ProductDetailTitle.scss';

const ProductDetailTitle = ({
    device,
    info,
    colors,
}) => {
    return (
        <>
            <div className="ProductDetailTitle">
                <Container>
                    <Row>
                        <Col>
                            <h1 style={{paddingLeft:'12px'}}>{device.name}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6></h6>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ProductDetailTitle;
