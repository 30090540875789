import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';

const PhonecallModal = ({
    showPhonecallModal,
    setShowPhonecallModal,
    submitPhone
}) => {

    const handleSelect = e => {
        submitPhone('T');
    };

    return (
        <Modal show={showPhonecallModal} onHide={e => setShowPhonecallModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    안내
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <center>
                    <br />
                    <h4>전화 상담을 진행합니다.</h4>
                    <br />
                    <p>전화 상담 대표번호 <b>1599-5168</b> 로 전화주시면 친전히 상담 드리겠습니다</p>
                    <br />
                    <Button variant="outline-secondary" onClick={handleSelect}>전화상담 진행</Button>
                    <br /><br /><br />
                </center>
            </Modal.Body>
        </Modal>);
};

export default PhonecallModal;
