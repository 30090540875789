import { Container, Table, Form, Button } from "react-bootstrap";
import axios from "axios";

import { CKEditor } from 'ckeditor4-react';

import { useSession } from '../../hooks/useSession.js';

const BoardManageContent = ({
    type,
    id,
    title,
    setTitle,
    contents,
    setContents,
}) => {

    const { getSession } = useSession();

    const ckeditorConfig = {
        defaultLanguage     : 'ko',
        language            : 'ko',
        height              : 500,
    };

    const handleSave = async () => {

        let session = getSession();

        var url = `https://api.tfamily.co.kr/api/board/create`;
        var params = {
            "uk"        : session.uk,
            "title"     : title,
            "contents"  : contents,
            "type"      : type.toLocaleUpperCase(),
        };

        if (id !== '0') {
            params.idx = id;
            url = `https://api.tfamily.co.kr/api/board/update`;
        }

        axios.get(url, { params: params})
             .then(response => {
                if (response.data.error_code === 200) {
                    window.location = `/board/${type}`;
                } else {
                    alert(response.data.error_msg);
                }
             });
    };

    return (<>
        <Container>
            <div className="board_title">
                <h3 className="board_notice_title">

                    {type === 'notice' &&
                        <>공지사항</>}

                    {type === 'review' &&
                        <>이벤트게시판</>}

                </h3>
            </div>
            <div className="pb-5">
                <div className="min-board-height">

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>제목</Form.Label>
                        <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <CKEditor
                                config={ckeditorConfig}
                                initData={contents}
                                onChange={(event, editor) => {
                                    setContents(event.editor.getData())
                                }}
                            />
                    </Form.Group>

                    <Button variant="primary" onClick={handleSave}>저장하기</Button>
                </div>
            </div>
        </Container>
    </>);
}

export default BoardManageContent;
