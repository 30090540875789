import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import './NavigationLinks.scss';

const NavigationLinks = () => {
    return (
        <>
            <div className="NavigationLinks">
                <Navbar variant="light">
                    <Container>
                        <Nav className="me-auto">
                            <Nav.Link href="/">티패밀리몰</Nav.Link>
                            <Nav.Link href="/board/notice">공지사항</Nav.Link>
                            <Nav.Link href="/board/review">이벤트</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        </>
    )
};

export default NavigationLinks;
