import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';

const ChatcallModal = ({
    showChatcallModal,
    setShowChatcallModal,
    submitPhone
}) => {

    const handleSelect = e => {
        submitPhone('C');
    };

    return (
        <Modal show={showChatcallModal} onHide={e => setShowChatcallModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    안내
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <center>
                    <br />
                    <h4>채팅 상담을 진행합니다.</h4>
                    <br />
                    <p>카카오톡 플러스 친구 "T패밀리몰"을 검색하시거나<br />아래 버튼 클릭 시 카카오톡으로 연결 됩니다.</p>
                    <br />
                    <Button variant="outline-secondary" onClick={handleSelect}>채팅상담 진행</Button>
                    <br /><br /><br />
                </center>
            </Modal.Body>
        </Modal>);
};

export default ChatcallModal;
