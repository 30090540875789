import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';

import "./ProductDetailFee.scss";

const ProductDetailFee = ({
    showFee,
    setShowFee,
    fee,
    setFee,
    fees,
}) => {

    const [local, setLocal] = useState(fee);

    const commas = x => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleSelect = e => {
        setFee(local);
        setShowFee(false);
    };

    return (
        <Modal show={showFee} onHide={e => setShowFee(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="ProductDetailFee">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    요금제 변경하기
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey={fees[0].name} className="mb-3">
                    {fees.map((fg, i) => {
                        if (i >=16)
                            return "";
                        return (
                            <Tab eventKey={fg.name} title={fg.name}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th style={{"width" : "30px"}}></th>
                                            <th>요금제명</th>
                                            <th>월정액</th>
                                            <th>데이터/음성/문자</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fg.fees.map(f => {
                                            return (
                                                <tr onClick={e => setLocal(f)}>
                                                    <td style={{"textAlign" : "center"}}>
                                                        <Form.Check type="radio" checked={local.name === f.name ? true : false} />
                                                    </td>
                                                    <td><b>{f.name}</b></td>
                                                    <td><b>{commas(f.price)}원</b></td>
                                                    <td>
                                                        <b>데이터</b> {f.data_amt} <br />
                                                        <b>음성</b> {f.call_amt} <br />
                                                        <b>문자</b> {f.sms_amt}
                                                    </td>
                                                   
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Tab>
                        )
                    })}
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSelect}>변경 요금제 적용</Button>
            </Modal.Footer>
        </Modal>);
};

export default ProductDetailFee;
