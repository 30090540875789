import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

const WelfareModal = ({
    welfareList,
    isWelfareModalVisible,
    setWelfareModalVisible,
    welfare,
    setWelfare,
    welItem,
    welItems,
    setWelItem
}) => {
    return (
        <Modal show={isWelfareModalVisible} onHide={e => setWelfareModalVisible(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    복지할인
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {welItem && welItems.map((w, i) => {
                        if (w.name === welItem.name)
                            return (<ListGroup.Item action onClick={e => {setWelItem(w); setWelfareModalVisible(false)}} active>{w.name}</ListGroup.Item>)
                        return (<ListGroup.Item action onClick={e => {setWelItem(w); setWelfareModalVisible(false)}}>{w.name}</ListGroup.Item>)
                    })}
                </ListGroup>
            </Modal.Body>
        </Modal>)
};

export default WelfareModal;
