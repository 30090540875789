import { useEffect } from 'react';
import { useSession } from '../hooks/useSession.js';

const Logout  = () => {

	const { setSession } = useSession();

    useEffect(() => {
        setSession({ });

		if (window.Kakao.Auth.getAccessToken())
			window.Kakao.Auth.logout();

        window.location = "/";
    }, []);

	return (<div></div>);
};

export default Logout;
