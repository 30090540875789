import { useEffect, useState } from 'react';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { useSession } from '../hooks/useSession.js';

const Lost  = () => {

	const { isLogin } = useSession();

	const [email, setEmail] = useState('');

	useEffect(() => {
		if (isLogin())
			window.location = "/";
	}, []);

	const handleSubmit = () => {

		if (email === "")
			return;

		let url = `https://api.tfamily.co.kr/api/main/sendTmpPasswd`;
        let headers = { };
        let params = { "email" : email };
		axios.get(url, { headers : headers, params: params})
            			  .then(response => {
							  	console.log(response);
						  		if (response.data.error_code === 200) {
									alert("임시 비밀번호가 이메일로 전송 되었습니다.")
									window.location = "/login/";
								} else {
								 	alert(response.data.error_msg);
								}
						  });
	};

	return (
		<>
			<Container>

				<br /><br /><br /><br />

				<Row>
				 	<Col></Col>
					<Col xs={4}>
						<h4>비밀번호 찾기</h4>
                        <h6 className="mb-4">가입시 입력하신 이메일 주소를 입력해주세요</h6>

						<FloatingLabel controlId="floatingInput" label="이메일 주소" className="mb-4">
							<Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} value={email} />
						</FloatingLabel>

						<div className="d-grid gap-2">
							<Button variant="primary" onClick={handleSubmit}>비밀번호 재설정 </Button>
						</div>
					</Col>
					<Col></Col>
				</Row>

				<br /><br />

				<Row className="font-14">
					<Nav className="justify-content-center">
						<Nav.Item>
							<Nav.Link className="link" href="/register/">회원가입</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link className="link" href="/login/">로그인</Nav.Link>
						</Nav.Item>
					</Nav>
				</Row>
			</Container>
		</>
	);
};

export default Lost;
