import { useEffect, useState } from 'react';

import { useSession } from '../../hooks/useSession.js';

import PhonecallModal from '../modals/PhonecallModal';
import ChatcallModal from '../modals/ChatcallModal';
import LegalModal from '../modals/LegalModal';
import OrderModal from '../modals/OrderModal';

import './ProductDetailSummary.scss';
import ProductDetailFee from './ProductDetailFee.js';
import CalendarModal from '../modals/CalendarModal.js';

const ProductDetailSummary = ({
    device,
    info,
    colors,

    memories,
    memory,
    activeColor,
    signupType,
    signupTarget,
    installment,
    fee,
    feetype,
    fees,
    moreFee,
    subsidy,
    allPrice,
    v1,
    v2,
    submitPhone,
    submitFull,

    ourCompany,
    setOurCompanyIdx,
    recalc2,

    monthFee,
    setMonthFee,

    ok
}) => {

    // 주문하기 동의서 모델
    const [showLegalModal, setShowLegalModal] = useState(false)
    const [showOrderModal, setShowOrderModal] = useState(false);

    // 전화 상담 모델
    const [showPhonecallModal, setShowPhonecallModal] = useState(false)
    

    // 채팅 상담 모델
    const [showChatcallModal, setShowChatcallModal] = useState(false)

    // 요금제선택 
    const [showFee, setShowFee] = useState(false);
    const [subFee, setSubFee] = useState({}); // 선택한 요금제 (api 에서 주는 구조체 임)
    //const [monthFee, setMonthFee] = useState(0);

    const [showCal, setShowCal] = useState(false);

    const [afterDay, setAfterDay] = useState(0);
    const [afterDate, setAfterDate] = useState(new Date());

    useEffect(()=>{
        if( afterDay !== 0 && subFee && subFee.title !== "" ) {
            let price = recalc2(subFee);
            setMonthFee(price + (v1 ? 3300 : 0) + (v2 ? 2900 : 0) );
        }
    },[afterDay, subFee, v1, v2, signupType, moreFee, subsidy, installment])

    useEffect(()=>{
        setTimeout( function() {  window.useFollow() } , 200);
        //setMonthFee(price + (v1 ? 3300 : 0) + (v2 ? 2900 : 0) );
    },[monthFee]);
    const commas = x => {
        if( x ) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "0";
    };

    function getDateString() {

        var year = afterDate.getFullYear();
        var month = ("0" + (1 + afterDate.getMonth())).slice(-2);
        var day = ("0" + afterDate.getDate()).slice(-2);
    
        return year + "-" + month + "-" + day; 
    }

    useEffect(()=>{
        window.useFollow();
    },[]);

    return (
        <>
            <div className="ProductDetailSummary">
                <div className="ProductDetailSummary-wrapper">

                    <div className="price-row-strong">
                        출고가
                        <span>{commas(device.release_price)}원</span>
                    </div>
                    {feetype === 0 && subsidy !== 0 ? <div className="price-row-strong">
                        공시지원금
                        <span>-{commas(subsidy)}원</span>
                    </div> : null}
                    
                    {moreFee !== 0 ? <div className="price-row-strong red">
                        복지금 할인
                        <span>-{commas(moreFee)}원</span>
                    </div>: null }

                    <div className="price-row-strong ">
                        할부원금
                        <span>{commas(device.release_price - moreFee - (feetype === 0 ? subsidy: 0))}원</span>
                    </div> 


                    <div className="price-row-line"></div>

                    <div className="price-row-strong">
                        월 통신요금
                        <span>월 {commas(fee.price)}원</span>
                    </div>

                    <div className="price-row-thin">
                        {fee.name}
                        <span>월 {commas(fee.price)}원</span>
                    </div>

                    {v1 ? <>
                        <div className="price-row-thin">
                            + V컬러링
                            <span>월 {commas(3300)}원</span>
                        </div>
                    </> : null}
                    {v2 ? <>
                        <div className="price-row-thin">
                            + 스마트콜Pick
                            <span>월 {commas(2900)}원</span>
                        </div>
                    </> : null}
                </div>

                <div className="ProductDetailSummary-grand-summary">
                    <div className="grand-title">
                        주문금액
                    </div>
                    <div className="grand-price">
                        월별 청구 금액
                        <div className="price">
                            <span>월</span>
                            &nbsp;{commas(allPrice+(v1 ? 3300 : 0)+(v2 ? 2900 : 0) ) }
                            <span>원</span>
                        </div>
                    </div>
                </div>
                <div className="ProductDetailSummary-grand-btns">
                    <div className={ ok ? "grand-red-btn" : "grand-gray-btn" } onClick={(e) => {
                                if(ok) {
                                    setShowLegalModal(true);
                                } else {
                                    alert("주문 불가 상태입니다.(품절)");
                                    //setShowLegalModal(false);
                                }
                            }
                         }>
                        <i className="fas fa-shopping-cart"></i>&nbsp;
                        주문하기
                    </div>

                    <div className={ ok ? "grand-call-btn" : "grand-gray-btn" } onClick={(e) => {
                        if(ok) {
                            setShowPhonecallModal(true);
                        } else {
                            alert("주문 불가 상태입니다.(품절)");
                        }
                    }}>
                        <i className="fas fa-headset"></i>&nbsp;
                        전화상담
                    </div>

                    <div className={ ok ? "grand-chat-btn" : "grand-gray-btn" } onClick={(e) => {
                        if(ok) {
                            setShowChatcallModal(true);
                        } else {
                            alert("주문 불가 상태입니다.(품절)");
                        }
                       
                    }}>
                        <i className="fas fa-comments"></i>&nbsp;
                        채팅상담
                    </div>
                </div>

                {ourCompany && ourCompany.length >0 ? <div className="ProductDetailSummary-wrapper" style={{paddingLeft:'12px', paddingRight:'12px'}}>
                    <div className="price-row-line"></div>                    
                    <div className="price-row-strong ">
                        요금제 변경시 예상요금
                        <div style={{textAlign:'right',paddingTop:'12px', paddingBottom:'6px'}}>
                            { afterDay !== 0 && subFee && subFee.name !== ""  ? <>{commas(monthFee)}원</> : <>미정</> }
                        </div>
                    </div> 

                    <div className="price-row-strong " style={{paddingTop:'12px', cursor: 'pointer'}}>
                        변경 요금제
                        <span style={{padding:'6px 8px',minWidth:'90px', textAlign:'center', backgroundColor:'#e2e2e2', border:'1px solid #cdcdcd'}} onClick={()=>{
                            setShowFee(true);
                        }}> {subFee && subFee.name ? <>{subFee.name}</> : <>요금제선택</>}</span>
                    </div> 


                    <div className="price-row-strong " style={{paddingTop:'16px', cursor: 'pointer'}}>
                        변경 가능일자
                        <span style={{padding:'6px 8px',minWidth:'90px', textAlign:'center', backgroundColor:'#e2e2e2', border:'1px solid #cdcdcd'}} onClick={()=>{
                            setShowCal(true);
                        }}> {afterDay === 0 ? <>일자 선택</> : afterDay === -1 ? <>서비스 유지</> :  <>{getDateString()}</>} </span>
                    </div> 

                </div> : null }
              

            </div>

            {showPhonecallModal &&
                <PhonecallModal
                        showPhonecallModal={showPhonecallModal}
                        setShowPhonecallModal={setShowPhonecallModal}
                        submitPhone={submitPhone}
                    /> }

            {showChatcallModal &&
                <ChatcallModal
                        showChatcallModal={showChatcallModal}
                        setShowChatcallModal={setShowChatcallModal}
                        submitPhone={submitPhone}
                        
                    /> }

            {showLegalModal &&
                <LegalModal
                        showLegalModal={showLegalModal}
                        setShowLegalModal={setShowLegalModal}
                        setShowOrderModal={setShowOrderModal}
                    /> }

            {showOrderModal &&
                <OrderModal
                        showOrderModal={showOrderModal}
                        setShowOrderModal={setShowOrderModal}

                        device={device}
                        info={info}
                        colors={colors}

                        memories={memories}
                        memory={memory}
                        activeColor={activeColor}
                        signupType={signupType}
                        signupTarget={signupTarget}
                        installment={installment}
                        fee={fee}

                        submitFull={submitFull}
                    />}

            {showFee && <ProductDetailFee 
                    showFee={showFee}
                    setShowFee={setShowFee}
                    fee={subFee}
                    setFee={setSubFee}
                    fees={fees}
                />}
            {showCal && <CalendarModal 
                showCal={showCal}
                setAfterDay={setAfterDay}
                setAfterDate={setAfterDate}
                setShowCal={setShowCal}/>}
        </>
    );
};

export default ProductDetailSummary;

/*
<div className="total-price">
    <div>273,000원</div>
</div>
<div className="price-row-strong">
    통신요금
    <span>월 79,000원</span>
</div>

<div className="price-row-thin">
    {fee.name}
    <span>월 79,000원</span>
</div>
<div className="price-row-red">
    단말할인 (공시지원금 24개월)
    <span>월 79,000원</span>
</div>
 <div className="grand-price">
            휴대폰 금액
            <span>(일시불)</span>
            <div className="price">
                79,000
                <span>원</span>
            </div>
        </div>
*/