import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Footer.scss';
const Footer = () => { 
    return <>
        <div className="Footer mt-5">
        <Row className='mt-3'>
            <Col></Col>
            <Col>
                <ul className="nonelist col-small">
                    <li>가입 및 상담 대표 번호 TEL: 1599-5168</li>
                    <li>아이디 및 비밀번호 분실시 대표 번호로 문의 바랍니다.</li>
                    <li>카카오 플러스 친구 "T패밀리몰" 검색</li>
                    <li><a href="/agree/our.html">서비스 이용약관</a>,<a href="/agree/private.html">개인정보 취급방침</a></li>
                    <li>개인정보 보호책임자 : 팀장 이지범</li>
                    <li>통신판매업 : 제2020-대구수성구-0289호</li>
                </ul>
            </Col>
            <Col>
                <ul className="nonelist">
                    <li>근무시간 : 평일 09시 ~ 20시</li>
                    <li>토요일 및 공휴일 11시 ~ 20시(일요일 휴무)</li>
                    <li>E-MAIL: 216-2265@naver.com</li>
                    <li>바른정보통신(사업자번호 : 662-88-00280) 대표자 : 최형종</li>
                    <li>대구시 수성구 달구벌대로 2349 수협G타워 901호</li>
                </ul>
            </Col>
        </Row>
        </div>
    </>;
}

export default Footer;