import { useEffect, useState } from 'react';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import { useSession } from '../hooks/useSession.js';

import NavigationSearchbar from '../components/navigations/NavigationSearchbar';
import NavigationLinks from '../components/navigations/NavigationLinks';

import ProductDetailTitle from '../components/products/ProductDetailTitle';
import ProductDetailImage from '../components/products/ProductDetailImage';
import ProductDetailOption from '../components/products/ProductDetailOption';
import ProductDetailSummary from '../components/products/ProductDetailSummary';
import ProductDetailFee from '../components/products/ProductDetailFee';

const Detail = param => {

    const { getSession, isLogin } = useSession();
    const [device, setDevice] = useState({});
    const [devices, setDevices] = useState([]);
    const [memories, setMemories] = useState([]);
    const [info, setInfo] = useState({});
    const [colors, setColors] = useState([]);
    const [fees, setFees] = useState([]);
    const [feetype, setFeetype] = useState(0);
    const [ourCompany, setOurCompany] = useState([]);
    const [ourCompanyIdx, setOurCompanyIdx] = useState(0);
    const [feetypeMonth, setFeetypeMonth] = useState(0);
    const [showFee, setShowFee] = useState(false)

    const [ok, setOk] = useState(true);
    const query = new URLSearchParams(window.location.search);

    // 신청 항목들
    const [memory, setMemory] = useState(0); // 선택 색상 idx
    const [activeColor, setActiveColor] = useState(0); // 선택 색상 idx
    const [signupType, setSignupType] = useState(query.get("sign_type")=== "C" ? 1 : query.get("sign_type") === "N" ? 0 : 2 ) // 가입 유형 idx (0신규가입, 1기기변경, 2번호이동))
    const [signupTarget, setSignupTarget] = useState(0) // 가입 대상 (0본인, 1타인(성인), 2타인(미성년자))
    const [installment, setInstallment] = useState(0) // 할부개월 (0-12개월, 1-24개월, 2-36개월)
    const [fee, setFee] = useState({}); // 선택한 요금제 (api 에서 주는 구조체 임)
    const [welfare, setWelfare] = useState(0); // see WelfareOptionList
    const [combinedDiscount, setCombinedDiscount] = useState(0); // see CombinedDiscountList

    const [moreFee, setMoreFee] = useState(0);
    const [subsidy, setSubsidy] = useState(0);
    const [allPrice, setAllPrice] = useState(0);

    //
    const [welItems, setWelItems] = useState([]);
    const [welItem, setWelItem] = useState(null);
    const [welItemDiscount, setWelItemDiscount] = useState(0);

    // 부가서비스 
    const [v1, setV1] = useState(false);
    const [v2, setV2] = useState(false);
    
    //
    const [monthFee, setMonthFee] = useState(0);
    let isNetworking = false;
    let emptyItem = {
        name: "미적용",
        type: 0,
        discount: 0,
        display_selected_item: false
    }
    const typeChar = ['N','C','M'];
    function replaceAll(str, searchStr, replaceStr) {
        return str.split(searchStr).join(replaceStr);
    }

    function recalc(paramFee, callFrom = "", paramMoreFee = 0) {
        if (isNetworking) {
            console.log("통신이 마무리될때까지 대기")
            return;
        }
        var disCountFee = 0;
        if( welItem !== null ) {
            if( welItem.type === 0 ) {
                disCountFee= 0;
            } else if( welItem.type === 1 ) {
                disCountFee = welItem.discount;
                if( fee.price > 41500 && welItem.discount_max != 0 ) {
                    disCountFee = welItem.discount_max;
                } else {
                disCountFee = Math.floor( welItem.discount + ((fee.price - welItem.discount) * 0.35) );
                }
                if (disCountFee > welItem.discount_max )
                {
                disCountFee = welItem.discount_max;
                }
            } else if(welItem.type === 2){
                // 퍼센트 할인
                // 퍼센트 할인이면 결합할인 가져오고 그 가격에서 퍼센트할인
                disCountFee = (fee.price - 0) * (welItem.discount / 100);
                if( disCountFee > welItem.discount_max &&  welItem.discount_max != 0) {
                    disCountFee = welItem.discount_max;
                }
            }
        }
        setWelItemDiscount(disCountFee);
        let tempMoreFee = moreFee;
        if (paramMoreFee !== 0 ) {
            console.log("Value change:", paramMoreFee)
            tempMoreFee = paramMoreFee
        }
        let devicePrice = device.release_price - (feetype === 0 ? subsidy : 0) - tempMoreFee;
        console.log("======:" + callFrom)
        console.log("devicePrice",  device.release_price)
        console.log("subsidy", subsidy)
        console.log("feetype", feetype)
        console.log("moreFee", tempMoreFee)
        console.log("devicePrice", devicePrice)
        console.log("installment", installment)
        console.log("disCountFee", disCountFee)
        console.log("======")
        let interest = 5.9;
        let months = [0,12,24,30,36];
        let divide_month = months[installment];
        console.log("divide_month", divide_month)
        console.log(divide_month != 0)
        var monthTotalPrice = 0;
        if( divide_month != 0) {
            monthTotalPrice =  Math.floor( devicePrice  * (interest / 100) / 12 * Math.pow(1+(interest / 100) / 12, divide_month) / (Math.pow(1 + (interest / 100) / 12, divide_month) - 1));
            setAllPrice( (Math.round(monthTotalPrice / 10) * 10) + getCurrentFee(paramFee) - disCountFee);
        } else {
            // 일시불
            setAllPrice(getCurrentFee(paramFee) - disCountFee);
        }
    }

    function recalc2(paramFee) {
        var disCountFee = 0;
        if( welItem !== null ) {
            if( welItem.type === 0 ) {
                disCountFee= 0;
            } else if( welItem.type === 1 ) {
                disCountFee = welItem.discount;
                if( fee.price > 41500 && welItem.discount_max != 0 ) {
                disCountFee = welItem.discount_max;
                } else {
                disCountFee = Math.floor( welItem.discount + ((fee.price - welItem.discount) * 0.35) );
                }
                if (disCountFee > welItem.discount_max )
                {
                disCountFee = welItem.discount_max;
                }
            } else if(welItem.type === 2){
                // 퍼센트 할인
                // 퍼센트 할인이면 결합할인 가져오고 그 가격에서 퍼센트할인
                disCountFee = (fee.price - 0) * (welItem.discount / 100);
                if( disCountFee > welItem.discount_max &&  welItem.discount_max != 0) {
                    disCountFee = welItem.discount_max;
                }
            }
        }
        //setWelItemDiscount(disCountFee);
        let devicePrice = device.release_price - (feetype === 0 ? subsidy : 0) - moreFee;
        let interest = 5.9;
        let months = [0,12,24,30,36];
        let divide_month = months[installment];
        var monthTotalPrice = 0;
        var resultPrice = 0;
        if( divide_month !== 0) {
            monthTotalPrice =  Math.floor( devicePrice  * (interest / 100) / 12 * Math.pow(1+(interest / 100) / 12, divide_month) / (Math.pow(1 + (interest / 100) / 12, divide_month) - 1));
            resultPrice = (Math.round(monthTotalPrice / 10) * 10) + getCurrentFee(paramFee) - disCountFee;
        } else {
            // 일시불
            resultPrice = getCurrentFee(paramFee) - disCountFee;
        }
        return resultPrice;
    }

    function getCurrentFee(paramFee) {
        let monthPrice = paramFee.price;// - this.getCurrentDivideDiscount();

        let select_promise_price = 0;
        var nSelPriceVat = Math.round( monthPrice * ( 25 / 100 ) );
        var nSelPrice = Math.round( ( monthPrice / 1.1 ) * ( 25 / 100 ) );
        var nMonthDcPricePoint = Math.floor(nSelPrice - nSelPrice);
        var lastPrice = 0;
        var nMonthPriceRemain = (monthPrice / 1.1) % 10;

        if ( nMonthDcPricePoint > 0 && !nMonthPriceRemain )
        {
            lastPrice = nSelPriceVat;
        } else {
            // 기본 선택약정 할인금액의 10의 자리 이하 값
            var nSelPrice100Above = parseInt((nSelPrice / 100));
            var nSelPrice100Below = nSelPrice - (nSelPrice100Above * 100);
            if ( $.inArray(nSelPrice100Below, [0, 5, 50]) >= 0 ) {
                lastPrice = nSelPriceVat;
            } else {
                if ( nSelPrice100Below < 50 ) {
                    lastPrice = (nSelPrice100Above + 0.5) * 100 * 1.1;
                } else {
                    lastPrice = (Math.round(Math.round(nSelPrice) / 100)) * 100 * 1.1;
                }
            }
        }
        select_promise_price = Math.floor(lastPrice);

        let result = paramFee.price - select_promise_price;
        if( feetype === 0 ) {
            return paramFee.price;
        }
        console.log("result", result);
        return result;
    }

    useEffect(() => {
        if (!isLogin()) {
            window.location = '/login/';
            return;
        }
        fetchData();
        fetchComs();
        
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            if (fee.idx) {
                console.log("*[fee, signupType]", moreFee);
                console.log("*signupType", signupType);
                await requestWelfare(true);
                await requestSubsidy();
            }
        };
        fetchData();
    }, [fee, signupType]);

    useEffect(() => {
        const fetchData = async () => {
            if (fee.idx) {
                await requestWelfare(true);
                await requestSubsidy();
                console.log("*[signupType, fee, installment, subsidy, moreFee]");
                recalc(fee, "*[signupType, fee, installment, subsidy, moreFee]");
            }
        };
        fetchData();
    }, [feetype]);
    
    useEffect(() => {
        const fetchData = async () => {
            if (fee.idx) {
                console.log("*[signupType, fee, installment, subsidy, moreFee]");
                recalc(fee, "*[signupType, fee, installment, subsidy, moreFee]");
            }
        };
        fetchData();
    }, [subsidy, moreFee, installment, welItem, feetype]);

    // useEffect(()=>{
    //     console.log("*subsidy, moreFee", moreFee)
    //     recalc(fee);
    // },[subsidy, moreFee, installment, welItem, feetype])
 
    useEffect(async()=>{
        if (ourCompanyIdx !== 0) {
            console.log("[ourCompanyIdx]")
            await requestWelfare()
        }
    },[ourCompanyIdx])

    useEffect(async()=>{
        if( device && device.name && fee.idx ) {
            console.log("[device]")
            await requestWelfare();
            recalc(fee, "[device]");
            if ( device.sold_out === 1 ) {
                setOk(false);
            } else {
                setOk(true);
            }
        }
    },[device])

    const updateDevice = (val) => {
        for ( var i = 0 ; i < devices.length ; i++) {
            let d = devices[i];
            if( d.mem === val ){
                setDevice(d);
                break;
            }
        }
    }

    const fetchComs = async() => {
        let session = getSession();
        let params = { "uk" : session.uk };
        let headers = {};
        let url = `https://api.tfamily.co.kr/api/mobile/coms`;
        await axios.get(url, { headers : headers, params: params})
        .then(response=>{
            setOurCompany(response.data.data);
        });
    }

    const fetchData = async () => {
        let session = getSession();
        let url = `https://api.tfamily.co.kr/api/mobile/models`;
        let headers = { };
        let params = { "uk" : session.uk };
        var device_ = {};
        await axios.get(url, { headers : headers, params: params})
            .then(response => {
                    if (response.data.error_code === 200) {
                        let group = response.data.data.find(i => i.PRODUCT_GRP_ID == parseInt(param.match.params.idx));
//용량 정렬가능? 
                        let mems = group.devices.map(d => d.mem);
                        setDevices(group.devices);
                        setMemories([...new Set(mems)].sort())

                        setDevice(group.devices[0]);

                        if( group.devices[0].sold_out === 1 ) {
                            setOk(false);
                        } else {
                            setOk(true);
                        }
                        device_ = group.devices[0];
                        setInfo(JSON.parse(device_.info));
                        setColors(JSON.parse(device_.color));
                    } else {
                        alert("Error:" + response.data.error_msg);
                    }
            });

        url = `https://api.tfamily.co.kr/api/mobile/fees`;
        headers = { };
        await axios.get(url, { headers : headers, params: params})
            .then(response => {
                  if (response.data.error_code === 200) {
                      if( device_.is_5g  ) {
                        let fees_ = response.data.data.find(i => i.is_5g == device_.is_5g);
                        setFee(fees_.fees[1])
                      } else {
                        let f = response.data.data[2];
                        setFee(f.fees[2]);
                        //setFee(response.data.data[3][3]);
                      }
                      setFees(response.data.data)
                  } else {
                      alert(response.data.error_msg);
                  }
            });
        //url = `https://api.tfamily.co.kr/api/mobile/support`;
    };

    const requestSubsidy = async () => {
        let session = getSession();
        let headers = {};
        let params = {
            "uk": session.uk,
            device_idx: device.idx,
            fee_idx: fee.idx,
            discount_type: feetype === 0 ? 'A' : 'B'  // Add this line
        };
        let url = 'https://api.tfamily.co.kr/api/mobile/subsidy';
        try {
            let response = await axios.get(url, { headers: headers, params: params });
            if (response.data.error_code === 200) {
                setSubsidy(response.data.data.discount);
                return response.data.data.discount;
            } else {
                alert(response.data.error_msg);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const submitFull = (name, startNumber, contactNumber, personalNumber, address,
        pay_company, pay_number, card_month, card_number) => {
        let session = getSession();
        const typeChar = ['N','C','M'];
        let months = [0, 12,24,30,36];
        let divide_month = months[installment];
        let color_name =  JSON.parse(device.color);
        $.post('https://api.tfamily.co.kr/api/mobile/submit', {
            uk: session.uk,
            device_idx: device.idx,
            fee_idx: fee.idx,
            support: moreFee,
            installment_month: divide_month,
            discount_type: feetypeMonth,
            sign_type: typeChar[signupType],
            welfare_idx: 0,
            contract_discount_month: 0,
            usim_idx: 0,
            insurance_idx : null,
            appl_type: null,
            color: color_name[activeColor].name,//self.state.data.colorname,
            additional_service: null,
            card_0_idx:  0,
            card_1_idx:  0,
            combined_discount: null,
            subsidy: subsidy,
            appl_name: name,
            appl_phone_number: startNumber,
            appl_reg_number: personalNumber,
            callback_phone_number: contactNumber,
            useraddress: address,
            pay_company: pay_company,
            pay_number: pay_number,
            card_month: card_month,
            card_number: card_number

      },function(res) {
        console.log(res);
        window.location.href = "https://tfamily.co.kr/OrderComplete";
      });

    }
    const submitPhone = (appl_type) => {
        let session = getSession();
        const typeChar = ['N','C','M'];
        let months = [0, 12,24,30,36];
        let divide_month = months[installment];
        let color_name =  JSON.parse(device.color);
        $.post('https://api.tfamily.co.kr/api/mobile/submit', {
            uk: session.uk,
            device_idx: device.idx,
            fee_idx: fee.idx,
            support: moreFee,
            installment_month: divide_month,
            discount_type: feetypeMonth,
            sign_type: typeChar[signupType],
            welfare_idx: 0,
            contract_discount_month: 0,
            usim_idx: 0,
            insurance_idx : null,
            appl_type: appl_type ? appl_type : 'C',
            color: color_name[activeColor].name,//self.state.data.colorname,
            additional_service: null,
            card_0_idx:  0,
            card_1_idx:  0,
            combined_discount: null,
            subsidy: subsidy
      },function() {
        if( appl_type === "C") {
            var newWin = window.open('http://pf.kakao.com/_xnZxcxej/chat');
            if(!newWin || newWin.closed || typeof newWin.closed=='undefined')
            {
              alert('팝업이 블록되었습니다. 블록 차단해주세요.');
            }
        } else {
            var newWin = window.open('tel:15995168');
        }
      });
    }
    const requestWelfare = async(pass = false) => {
        if (isNetworking) {
            return
        }
        isNetworking = true;
        let session = getSession();
        let url = `https://api.tfamily.co.kr/api/mobile/support`;
        let headers = { };
        let params = {"uk" : session.uk,
            device_idx: device.idx,
            fee_idx: fee.idx,
            discount_type: feetype === 0 ? 'A' : 'B',
            sign_type: typeChar[signupType] };
    
        if( ourCompanyIdx != 0 ) {
            params.company_idx = ourCompanyIdx;
        }
        try {
            let response = await axios.get(url, { headers : headers, params: params });
            console.log(response)
            if (response.data.error_code === 200) {
                let d = response.data.data;
                console.log("setMoreFee", d)
                setMoreFee(d);
                isNetworking = false;
                recalc(fee, "setMoreFee", d);
                return d;
            } else {
                alert(response.data.error_msg);
                isNetworking = false;
            }
    
            if (!pass) {
                let baseResponse = await axios.get('https://api.tfamily.co.kr/api/mobile/base', { headers : headers, params: params });
                if (baseResponse.data.error_code === 200) {
                    let parsedData = baseResponse.data.data.welfare, displaylist = [];
                    let welfare_items = parsedData;
                
                    if( welItem === null) {
                        emptyItem.display_selected_item = true;
                        setWelItem(emptyItem);
                    }
                    displaylist.push(emptyItem);
    
                    for (let i = 0 ; i < welfare_items.length ; i++) {
                        let items = welfare_items[i];
    
                        if(welItem && welItem.name === items.name) {
                            welfare_items[i].display_selected_item = true;
                            setWelItem(welfare_items[i]);
                        } else {
                            welfare_items[i].display_selected_item = false;
                        }
                        displaylist.push(welfare_items[i]);
                    }
                
                    setWelItems(displaylist);
                    console.log("*base", welfare_items);
                    recalc(fee);
                }
            }
        } catch (error) {
            console.error(error);
            isNetworking = false;
        }
    };
    

    
    var displayField = "";
    var typeField = "";
    if ( info.find ) {
        displayField = info.find(item => item.field === "크기");
        typeField = info.find(item => item.field === "디스플레이");
    }

    return (
        <>
            <NavigationSearchbar />

            <br />

            {"idx" in device &&
                <>
                    <ProductDetailTitle
                            device={device}
                            info={info}
                            colors={colors}
                        />

                    

                    <Container>
                        <Row>
                            <Col md={4} xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <ProductDetailImage
                                        device={device}
                                        info={info}
                                        colors={colors}
                                        activeColor={activeColor}
                                        setActiveColor={setActiveColor}
                                    />
                                    <br />
                                    <div className="px-2">
                                    <table className="maintable" >
                                        <thead></thead>
                                        <tbody>
                                        {displayField && 'field' in displayField &&
                                            <tr>
                                                <td style={{width:'100px'}}><b>디스플레이</b></td>
                                                <td>
                                                 <div dangerouslySetInnerHTML={{ __html: replaceAll( displayField.value.trim(), "\n" , "<br/>") }} />
                                                </td>
                                            </tr>}
                                        {typeField && 'field' in typeField &&
                                        <>
                                            <tr><td colSpan={2}><br/></td></tr>
                                            <tr>
                                                <td style={{width:'100px'}}><b>화면종류</b></td>
                                                <td>
                                                    <div dangerouslySetInnerHTML={{ __html: replaceAll( typeField.value.trim(), "\n", "<br/>") }} />
                                                </td>
                                            </tr>
                                        </>}
                                        </tbody>
                                    </table>
                                    </div>
                                    <div>
                                    {ourCompany.length > 0 ?
                                        <div>
                                        <br/>
                                            <div>회사변경</div>
                                            <select onChange={(e)=>{
                                                setOurCompanyIdx(e.target.value)
                                            }}  className="form-control">
                                            <option value=""></option>
                                                {ourCompany.map((item, index)=>{
                                                return <option  key={"a"+item.idx} value={item.idx}>{item.name}</option>;
                                                })}
                                            </select>
                                        </div> : null}
                                        
                                    </div>
                            </Col>
                            <Col md={5} xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                               <ProductDetailOption
                                        memories={memories}

                                        memory={memory}
                                        setMemory={setMemory}

                                        device={device}
                                        setDevice={setDevice}
                                        info={info}
                                        colors={colors}

                                        activeColor={activeColor}
                                        setActiveColor={setActiveColor}

                                        feetype={feetype}
                                        setFeetype={setFeetype}

                                        signupType={signupType}
                                        setSignupType={setSignupType}

                                        signupTarget={signupTarget}
                                        setSignupTarget={setSignupTarget}

                                        installment={installment}
                                        setInstallment={setInstallment}

                                        fee={fee}
                                        setShowFee={setShowFee}

                                        feetypeMonth={feetypeMonth}
                                        setFeetypeMonth={setFeetypeMonth}

                                        v1={v1}
                                        setV1={setV1}

                                        v2={v2}
                                        setV2={setV2}
                                        welfare={welfare}
                                        setWelfare={setWelfare}

                                        combinedDiscount={combinedDiscount}
                                        setCombinedDiscount={setCombinedDiscount}

                                        welItem={welItem}
                                        welItems={welItems}

                                        setWelItems={setWelItems}
                                        setWelItem={setWelItem}


                                        updateDevice={updateDevice}
                                    />
                            </Col>
                            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <ProductDetailSummary
                                        device={device}
                                        info={info}
                                        colors={colors}

                                        memories={memories}
                                        memory={memory}
                                        activeColor={activeColor}
                                        signupType={signupType}
                                        signupTarget={signupTarget}
                                        installment={installment}
                                        fee={fee}
                                        feetype={feetype}
                                        fees={fees}
                                        moreFee={moreFee}
                                        subsidy={subsidy}

                                        allPrice={allPrice}
                                        v1={v1}
                                        v2={v2}

                                        submitPhone={submitPhone}
                                        submitFull={submitFull}

                                        ourCompany={ourCompany}
                                        setOurCompanyIdx={setOurCompanyIdx}
                                        recalc2={recalc2}

                                        monthFee={monthFee}
                                        setMonthFee={setMonthFee}

                                        ok={ok}

                                    />
                            </Col>
                        </Row>
                        {device && device.description ? <>
                            <Row className="pt-5">
                                <Col md={8} xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <div><b>제품 상세</b></div>
                                    <div dangerouslySetInnerHTML={{__html: device.description}}>
                                    
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </> : null}
                        
                    </Container>

                    {showFee &&
                        <ProductDetailFee
                                showFee={showFee}
                                setShowFee={setShowFee}
                                fee={fee}
                                setFee={setFee}
                                fees={fees}
                            />}
                </>}
        </>
    );
};

export default Detail;
