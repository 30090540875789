import { Button, Container, Table } from "react-bootstrap";
import { useSession } from "../../hooks/useSession";
import axios from "axios";
const BoardViewContent = ({
    type,
    board,
}) => {
    const { getSession } = useSession();
    return (<>
        <Container>
            <div className="board_title">
                <h3 className="board_notice_title">

                    {type === 'notice' &&
                        <>공지사항</>}

                    {type === 'review' &&
                        <>이벤트게시판</>}

                </h3>
                <br />
            </div>
            <div className="pb-5 px-3">
                <div className="min-board-height">
                    <div>
                        <div className="text-title">{board.title}</div>
                        <div className="text-right">
                            {board.created_at}
                        </div>
                    </div>
                    <hr />
                    <div dangerouslySetInnerHTML={{__html: board.contents}} />
                    
                    <div style={{height:'200px'}}>

                    </div>
                    <div style={{textAlign:'center'}}>
                    {board.is_writer === 1 ? <Button onClick={()=>{
                        if(window.confirm("삭제하시겠습니까?")){
                            let session = getSession();
                            let url = `https://api.tfamily.co.kr/api/board/delete`;
    		                let params = { "uk" : session.uk,  "idx" : board.idx };
                            axios.get(url + "?uk=" + session.uk + "&idx=" + board.idx, {params: params})
                                .then(response => {
                                    if (response.data.error_code === 200) {
                                        alert("삭제되었습니다");
                                    } else {
                                        alert(response.data.error_msg);
                                    }
                                    window.history.back();
                                });
                        }
                    }} variant="light">삭제하기</Button> : null }
                    <Button style={{marginLeft:'12px'}} onClick={()=>{
                        window.history.back();
                    }} variant="light">목록으로</Button>
                    </div>
                </div>
            </div>
        </Container>
    </>);
}

export default BoardViewContent;
