import { useEffect, useState } from 'react';

import './ProductDetailOption.scss';

import WelfareModal from '../modals/WelfareModal.js';
import CombinedDiscountModal from '../modals/CombinedDiscountModal.js';
import CombinedListModal from '../modals/CombinedListModal.js';

const ProductDetailOption = ({
    memories,

    memory,
    setMemory,

    device,
    setDevice,
    info,
    colors,

    activeColor,
    setActiveColor,

    signupType,
    setSignupType,

    signupTarget,
    setSignupTarget,

    installment,
    setInstallment,

    fee,
    setShowFee,

    feetype,
    setFeetype,

    feetypeMonth,
    setFeetypeMonth,

    v1,
    setV1,
    
    v2,
    setV2,
    welfare,
    setWelfare,

    combinedDiscount,
    setCombinedDiscount,

    welItem,
    welItems,

    setWelItems,
    setWelItem,

    updateDevice
}) => {

    // WelfareOptionList
    const welfareList = [
        "미적용",
        "장애인(35%할인)",
        "국가유공자(35%)",
        "기초생활수급자 주거/교육(12,100원 할인, 최대 23,650원)",
        "차상위계층(12,100원 할인, 최대 23,650원 할인)",
        "만 65세 이상 기초연금수급자(최대 12,100원)",
        "기초생활수급자 생계/의료(28,600원 할인)",
    ]

    // CombinedDiscountList
    const combinedList = [
        "미적용",
        "온가족플랜",
        "온가족프리",
        "한가족할인",
        "온가족할인",
    ]

    const [isWelfareModalVisible, setWelfareModalVisible] = useState(false) // 복지
    const [isCombinedDiscountModalVisible, setCombinedDiscountModalVisible] = useState(false) // 유무선 결합 설정
    const [isCombinedListModalVisible, setCombinedListModalVisible] = useState(false) // 유무선 결합 설정

    const commas = x => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            <div className="ProductDetailOption">

                <div className="option-title">
                    용량
                </div>

                <div className="option-group">
                    {memories.map((m, index) => {
                        return  (
                            <div className={memory === index ? "option-item option-active" : "option-item"} 
                            onClick={(e) => { 
                                    updateDevice(m);
                                    setMemory(index);
                                }}>
                                {memory === index ? "✓ " : ""}
                                {m}
                            </div>
                        )
                    })}
                </div>

                <div className="option-title">
                    색상
                    <div className="tip">

                    </div>
                </div>

                <div className="option-color-picker">
                    {colors.map((c, i) => {
                        return (
                            <div key={i} className="color-item-wrapper" style={i === activeColor ? {borderColor : '#f43142'} : {}} onMouseEnter={e => setActiveColor(i)}>
                                <div className="color-item" style={{backgroundColor : `#${c.hex}`}}></div>
                            </div>
                        )
                    })}
                </div>

                <div className="option-title">
                    가입 유형 및 대상
                    <div className="tip">

                    </div>
                </div>

                <div className="option-group">
                    <div className={signupType === 0 ? "option-item option-active" : "option-item"} onClick={e => setSignupType(0)}>
                        신규가입
                    </div>
                    <div className={signupType === 1 ? "option-item option-active" : "option-item"} onClick={e => setSignupType(1)}>
                        기기변경
                    </div>
                    <div className={signupType === 2 ? "option-item option-active" : "option-item"} onClick={e => setSignupType(2)}>
                        번호이동
                    </div>
                </div>

                <div className="option-with-drop">
                    <div className="option-group">
                        <div className={signupTarget === 0 ? "option-item option-active" : "option-item"} onClick={e => setSignupTarget(0)}>
                            본인
                        </div>
                        <div className={signupTarget === 1 ? "option-item option-active" : "option-item"} onClick={e => setSignupTarget(1)}>
                            가족(성인)
                        </div>
                        <div className={signupTarget === 2 ? "option-item option-active" : "option-item"} onClick={e => setSignupTarget(2)}>
                            가족(미성년자)
                        </div>
                    </div>
                </div>

                <div className="option-title">
                    약정
                </div>

                <div className="option-group">
                    <div className={feetype === 0 ? "option-item size50 option-active" : "option-item size50"} onClick={e => setFeetype(0)}>
                        공시지원금
                        <div style={{fontSize:'11px'}}>개통 후 D+181일 이후 변경가능</div>
                    </div>
                    <div className={feetype === 1 ? "option-item size50 option-active" : "option-item size50"} onClick={e => setFeetype(1)}>
                        선택약정
                        <div style={{fontSize:'11px'}}>M+3개월 이후 변경가능</div>
                    </div>
                </div>

                {feetype === 1 ?  <>
                <div className="option-title">
                    선택약정
                </div>
                <div className="option-group">
                    <div className={feetypeMonth === 0 ? "option-item size50 option-active" : "option-item size50"} onClick={e => setFeetypeMonth(0)}>
                        12개월
                    </div>
                    <div className={feetypeMonth === 1 ? "option-item size50 option-active" : "option-item size50"} onClick={e => setFeetypeMonth(1)}>
                        24개월
                    </div>
                </div>
                </> : null }

                <div className="option-title">
                    할부개월
                </div>

                <div className="option-group">
                    <div className={installment === 0 ? "option-item  option-active" : "option-item "} onClick={e => setInstallment(0)}>
                        일시불
                    </div>
                    <div className={installment === 1 ? "option-item  option-active" : "option-item "} onClick={e => setInstallment(1)}>
                        12개월
                    </div>
                    <div className={installment === 2 ? "option-item  option-active" : "option-item "} onClick={e => setInstallment(2)}>
                        24개월
                    </div>
                </div>

                <div className="option-title">
                    요금제 선택
                </div>

                {'name' in fee &&
                    <div className="option-plan">
                        <div className="option-plan-header">
                            <div className="plan-name">
                                {fee.name}

                                <span>
                                    월 {commas(fee.price)} 원
                                </span>
                            </div>

                            <div className="plan-botton" onClick={e => setShowFee(true)}>
                                요금제 변경
                            </div>
                        </div>
                        <div className="option-plan-body">
                            <b>데이터</b> {fee.data_amt} <br />
                            <b>음성</b> {fee.call_amt} <br />
                            <b>문자</b> {fee.sms_amt}
                        </div>
                    </div>}

                <div className="option-title">
                    복지할인
                </div>

                <div className="option-plan">
                     <div className="option-plan-header">
                            <div className="plan-name">
                                {welItem ? welItem.name : null}
                            </div>

                            <div className="plan-botton" onClick={e => setWelfareModalVisible(true)}>
                                할인 적용
                            </div>
                        </div>
                </div>

                <div className="option-title" style={{display:'none'}}>
                    결합할인
                </div>

                <div className="option-plan" style={{display:'none'}}>
                     <div className="option-plan-header">
                            <div className="plan-name">
                                {combinedList[combinedDiscount]}
                            </div>

                            <div className="plan-botton" onClick={e => setCombinedDiscountModalVisible(true)}>
                                할인 적용
                            </div>
                        </div>
                </div>

                { false ? <div className="option-title">
                    부가 서비스 선택
                </div> : null}

                {false ? <div className="hide option-list">
                    <div className={v1 ? "option-list-item option-active" : "option-list-item"} onClick={()=>{
                        setV1(!v1);
                    }}>
                        <div className="list-item-col">
                            <div className="item-title">
                                V컬러링
                            </div>
                            <div className="item-subtitle">
                                보이는 컬러링 (영상재생)
                            </div>
                        </div>
                        <div className="list-item-col">
                            <div className="item-title text-right">
                                3,300원
                            </div>
                        </div>
                    </div>
               
                    <div className={v2 ? "option-list-item option-active mt-1" : "option-list-item  mt-1"} onClick={()=>{
                        setV2(!v2);
                    }}>
                        <div className="list-item-col">
                            <div className="item-title">
                            스마트콜Pick
                            </div>
                            <div className="item-subtitle">
                            구성상품 11종 중 5종 선택하여 사용가능한 상품(컬러링, 콜키퍼, 통화가능통보플러스, 안심문자, 개별통화수신거부, 착신전환 등)
                            </div>
                        </div>
                        <div className="list-item-col">
                            <div className="item-title text-right">
                               2,900원
                            </div>
                        </div>
                    </div>
                </div> : null}
                <br/><br/>
            </div> 

            {isWelfareModalVisible &&
                <WelfareModal
                        welfareList={welfareList}
                        isWelfareModalVisible={isWelfareModalVisible}
                        setWelfareModalVisible={setWelfareModalVisible}
                        welfare={welfare}
                        setWelfare={setWelfare}

                        welItem={welItem}
                        welItems={welItems}
                        setWelItem={setWelItem}
                    />}

            {isCombinedDiscountModalVisible &&
                <CombinedDiscountModal
                        combinedList={combinedList}
                        isCombinedDiscountModalVisible={isCombinedDiscountModalVisible}
                        setCombinedDiscountModalVisible={setCombinedDiscountModalVisible}
                        combinedDiscount={combinedDiscount}
                        setCombinedDiscount={setCombinedDiscount}

                        isCombinedListModalVisible={isCombinedListModalVisible}
                        setCombinedListModalVisible={setCombinedListModalVisible}
                    />}

            {isCombinedDiscountModalVisible &&
                <CombinedListModal
                        combinedList={combinedList}

                        combinedDiscount={combinedDiscount}
                        setCombinedDiscount={setCombinedDiscount}

                        isCombinedListModalVisible={isCombinedListModalVisible}
                        setCombinedListModalVisible={setCombinedListModalVisible}
                    />}
        </>
    );
};

export default ProductDetailOption;

/* {welfareList[welfare]} */