import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import BoardManageContent from '../components/boards/BoardManageContent.js';
import Footer from '../components/navigations/Footer.js';
import NavigationSearchbar from '../components/navigations/NavigationSearchbar';
import NavigationLinks from '../components/navigations/NavigationLinks';

import { useSession } from '../hooks/useSession.js';

const BoardManage  = (props) => {

    const { getSession } = useSession();

    const { type, id } = useParams();
    const [loaded, setLoaded] = useState(false)
    const [title, setTitle] = useState("")
    const [contents, setContents] = useState("")

    useEffect(() => {
        let fetchData = async () => {
            let session = getSession();
            let url = `https://api.tfamily.co.kr/api/board/get`;
    		let params = { "uk" : session.uk, ...{ "idx" : id } };
    		axios.get(url, { params: params})
    			 .then(response => {
    				if (response.data.error_code === 200) {
                        setTitle(response.data.data.title);
                        setContents(response.data.data.contents);
                        setLoaded(true)
    				} else {
    					alert(response.data.error_msg);
    				}
    			 });
        };
        if (id !== '0')
            fetchData();
    }, []);

    return (
        <>
            <NavigationSearchbar />
            <NavigationLinks />
            {id === '0' || loaded ? (
                <BoardManageContent
                        type={type}
                        id={id}
                        title={title}
                        setTitle={setTitle}
                        contents={contents}
                        setContents={setContents}
                    />
            ) : ""}
            <Footer />
        </>);
}

export default BoardManage;
