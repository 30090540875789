import { useEffect, useState } from 'react';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { useSession } from '../hooks/useSession.js';

import NavigationSearchbar from '../components/navigations/NavigationSearchbar';
import NavigationLinks from '../components/navigations/NavigationLinks';

const Reset  = () => {

	const { getSession, isLogin } = useSession();

	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');

	useEffect(() => {
		if (!isLogin())
			window.location = "/";
	}, []);

	const handleSubmit = () => {

		if (password === "" || password2 === "")
			return;

		if (password !== password2) {
			alert("비밀번호가 일치하지 않습니다.")
			return;
		}

		let session = getSession();
        console.log("session", session);
		let url = `https://api.tfamily.co.kr/api/account/changePasswd`;
        let headers = { };
        let params = { "uk" : session.uk, "passwd" : password };
		axios.get(url, { headers : headers, params: params})
            			  .then(response => {
							  	console.log(response.data)
						  		if (response.data.error_code === 200) {
									alert("비밀번호 변경 되었습니다.")
									window.location = "/";
								} else {
								 	alert(response.data.error_msg);
								}
						  });
	};

	return (
		<>

			<NavigationSearchbar />

			<Container>

				<br /><br /><br /><br />

				<Row>
				 	<Col></Col>
					<Col md={4} xs={12}>
						<h4>비밀번호 변경</h4>

						<FloatingLabel controlId="floatingPassword" label="신규 비밀번호" className="mb-4">
							<Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} />
						</FloatingLabel>

						<FloatingLabel controlId="floatingPassword" label="신규 비밀번호 확인" className="mb-4">
							<Form.Control type="password" placeholder="Password" onChange={e => setPassword2(e.target.value)} value={password2} />
						</FloatingLabel>

						<div className="d-grid gap-2">
							<Button variant="primary p-2" onClick={handleSubmit}><b>비밀번호 변경</b></Button>
						</div>
					</Col>
					<Col></Col>
				</Row>

				<br /><br />

			</Container>
		</>
	);
};

export default Reset;
