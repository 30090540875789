import { useEffect, useState } from 'react';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSession } from '../../hooks/useSession.js';
import { usePopup } from '../../hooks/usePopup.js';
import $ from 'jquery';
import "./PopupModal.scss";

const PopupFormModal = ({
    isFormVisible,
    setFormVisible,
}) => {

    const { setPopupCookie, getPopupCookie, isPopupHide } = usePopup();
    const { getSession, isLogin } = useSession();
    const [modelName, setModelName] = useState('');
    const [telcom, setTelcom] = useState('');
    const [name, setName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [address, setAddress] = useState('');
    const [company, setCompany] = useState('');
    const [target, setTarget] = useState('');

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const handleSelect = e => {
        let session = getSession();
        if( modelName === "" ) {
            window.alert("모델명/용량/색상을 기입해주세요");
            return false;
        }
        if( contactNumber === "" ) {
            window.alert("성함을 기입해주세요");
            return false;
        }

        if( address === "" ) {
            window.alert("주소를 기입해주세요");
            return false;
        }
        const frm = new FormData()

        $.post('https://api.tfamily.co.kr/api/mobile/reserve',{
            uk: "",
            device: modelName,
            current_agency: telcom,
            username: name,
            birthday: birthday,
            phone_number: mobileNumber,
            available_phone_number: contactNumber,
            address0: address,
            company_name: company,
            relationship: target
        }).then(response => {
            let result = JSON.parse(response);
            if (result.error_code === 200) {
                alert('접수 되었습니다. 감사합니다.');
                window.location.reload();
            } else {
                alert('에러가 발생하였습니다. 다시 시도해주세요');
            }
        })

        
        return false;
    };

    return (
        <Modal className="PopupModal" show={isFormVisible} onHide={e=>setFormVisible(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div style={{padding: "10px"}}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>모델명 / 용량 / 색상</Form.Label>
                            <Form.Control type="text" value={modelName} onChange={e => setModelName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>현재 이용중인 통신사</Form.Label>
                            <Form.Control type="text" value={telcom} onChange={e => setTelcom(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>성함</Form.Label>
                            <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>생년월일 <small>1990-01-01</small></Form.Label>
                            <Form.Control type="text" value={birthday} onChange={e => setBirthday(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>휴대폰번호</Form.Label>
                            <Form.Control type="text" value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>연락가능번호</Form.Label>
                            <Form.Control type="text" value={contactNumber} onChange={e => setContactNumber(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>주소</Form.Label>
                            <Form.Control type="text" value={address} onChange={e => setAddress(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>근무 회사명 / 직원성함</Form.Label>
                            <Form.Control type="text" value={company} onChange={e => setCompany(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>사전예약자와의 관계 (본인 or 가족)</Form.Label>
                            <Form.Control type="text" value={target} onChange={e => setTarget(e.target.value)} />
                        </Form.Group>

                        <Form.Text className="text-muted">
                            예약정보 입력시 정보 미기재 또는 오기재(고객과실로 인한) 예약 누락시 당사에서 책임 및 보상 의무가 없음을 알려드립니다.
                        </Form.Text>

                        <br /><br />

                        <center>
                            <Button  variant="primary" onClick={handleSelect}>제출</Button>
                        </center>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>);
};

export default PopupFormModal;
