import Pagination from 'react-bootstrap/Pagination'

const BoardPagination = ({

    _baseUrl = "",
    _currentPage,
    _limitPerPage,
    _totalCount,
    _extraParams = {},

    onclickPage = function(i) {}
}) => {

    const urlEncode = (key, val) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(val);
    };

    const convertDicToUrl = (obj) => {
        var str = [];
        for(var p in obj)
            str.push(urlEncode(p, obj[p]));
        return str.join("&");
    };

    var fb_cnt  = 5;   // <------ 이게 전체 길이 말하는 거임~!!!!!!!!!!
    var limit   = Math.ceil(_totalCount/_limitPerPage);
    var start   = (_currentPage < fb_cnt ) ? 1 : _currentPage - fb_cnt + 1;
    var end     = (_currentPage < fb_cnt ) ? _currentPage + (fb_cnt*2-1) : _currentPage + fb_cnt;
        end     = (end > limit) ? limit : end;
    var pages   = [];
    var double_front_arrow = true;
    var double_front_arrow_value = 0;
    var front_arrow = true;
    var front_arrow_value = 0;
    var back_arrow  = true;
    var back_arrow_value = 0;
    var double_back_arrow  = true;
    var double_back_arrow_value = 0;

    for (var i = start; i <= end; i++) {
        var item = { "num" : i, "cls" : "" };
        if (_currentPage === i)
            item.cls = "active";
        pages.push(item);
    }

    double_front_arrow_value = (_currentPage - fb_cnt > 0) ? _currentPage - fb_cnt : 1;
    front_arrow_value = ((_currentPage - 1) <= 0) ? 1 : (_currentPage - 1);
    back_arrow_value = ((_currentPage + 1) <= limit) ? (_currentPage + 1) : limit;
    double_back_arrow_value = (_currentPage + fb_cnt <= limit) ? _currentPage + fb_cnt : limit;

    var params = convertDicToUrl(_extraParams);

    return (
        <>
            <Pagination style={{justifyContent: "center"}}>
                {double_front_arrow && pages.length >= 2 ? (
                    <Pagination.First
                            onClick={(e)=>{onclickPage(double_front_arrow_value, _baseUrl + "?" + urlEncode("page", double_front_arrow_value) + "&" + params )}}
                        />
                ) : ""}

                {front_arrow && pages.length >= 2 ? (
                    <Pagination.Prev
                            onClick={(e)=>{onclickPage(front_arrow_value, _baseUrl + "?" + urlEncode("page", front_arrow_value) + "&" + params )}}
                        />
                ) : ""}

                {pages.map((p, i) => {
                    let u = _baseUrl + "?" + urlEncode("page", p.num) + "&" + params;
                    return (
                      <Pagination.Item onClick={(e)=>{onclickPage(p.num, u)}}>
                        {p.num}
                      </Pagination.Item>
                    )
                })}

                {back_arrow && pages.length >= 2 ? (
                    <Pagination.Next
                            onClick={(e)=>{onclickPage(back_arrow_value, _baseUrl + "?" + urlEncode("page", back_arrow_value) + "&" + params )}}
                        />
                ) : ""}

                {double_back_arrow && pages.length >= 2 ? (
                    <Pagination.Last
                            onClick={(e)=>{onclickPage(double_back_arrow_value, _baseUrl + "?" + urlEncode("page", double_back_arrow_value) + "&" + params )}}
                        />
                ) : ""}
            </Pagination>
        </>
    );
};

export default BoardPagination;
